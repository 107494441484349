import { useDisclosure } from '@chakra-ui/react';
import { ColumnProps } from 'primereact/column';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ContractRenewalIndicator } from '../../../components/Contracts/ContractRenewalIndicator';
import {
  MBox,
  MDataTable,
  MFlex,
  MHStack,
  MPageHeader,
  MTag,
  MText,
  MTooltip,
} from '../../../components/Monetize';
import { MDataTableFilter } from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { RenewalModal } from '../../../components/RenewalModal/RenewalModal.component';
import { CONTRACTS, ROUTES } from '../../../constants';
import {
  getAccountQuotesRoute,
  getBillGroupPageRoute,
} from '../../../constants/routes';
import {
  IContractWithBillGroup,
  useContractsData,
} from '../../../hooks/AccountDetail';
import { useACL } from '../../../services/acl/acl';
import {
  ContractEndActionEnum,
  ContractStatusEnum,
  IAccountRespSchema,
  IBillGroupResp,
} from '../../../types';
import { formatCurrency, getFiltersApplied } from '../../../utils';
import {
  dateBodyTemplate,
  idWithExtraBodyTemplate,
  nameWithIdBodyTemplate,
  statusBodyTemplate,
} from '../../../utils/tableUtils';
import ContractActions from '../../Quotes/Quote/ContractActions';
import { CONTRACT_FILTER_OPTIONS_FOR_ACCOUNT_DETAIL } from '../../Sales/ContractListMain';

const ContractListAccount = ({
  account,
  billGroupId,
}: {
  account: IAccountRespSchema;
  billGroupId?: string;
}) => {
  const navigate = useNavigate();
  const { id: accountId } = account;

  const [contract, setSelectedContract] =
    React.useState<IContractWithBillGroup | null>(null);

  const {
    isOpen: isOpenRenewalModal,
    onOpen: onOpenRenewalModal,
    onClose: onCloseRenewalModal,
  } = useDisclosure();

  const {
    filters,
    setFilters,
    searchKey,
    searchTerm,
    onResetFilter,
    listData,
    pager,
    setPager,
    loading,
  } = useContractsData(accountId, billGroupId);
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);

  const onSelectRow = (rowData: IContractWithBillGroup) => {
    if (canUpdateSales) {
      if (billGroupId) {
        navigate(
          ROUTES.getBillGroupContractsEditRoute(billGroupId, rowData.id),
        );
      } else {
        navigate(ROUTES.getAccountContractEditRoute(accountId, rowData.id));
      }
    }
  };

  const totalBodyTemplate = (data: IContractWithBillGroup) => (
    <MTooltip label={data.totalValue} placement="bottom-end">
      <MText isTruncated noOfLines={1}>
        {formatCurrency(data.totalValue, { currency: account.defaultCurrency })}
      </MText>
    </MTooltip>
  );

  const handleEarlyRenewal = (data: IContractWithBillGroup) => {
    setSelectedContract(data);
    onOpenRenewalModal();
  };

  const actionBodyTemplate = (data: IContractWithBillGroup) => {
    return (
      canUpdateSales && (
        <ContractActions
          contract={data}
          handleEarlyRenewal={handleEarlyRenewal}
        />
      )
    );
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'id',
      header: 'ID',
      body: idWithExtraBodyTemplate<IContractWithBillGroup>(
        'id',
        (contract) => <ContractRenewalIndicator contract={contract} />,
        {
          extraProps: {
            justifyContent: 'space-between',
          },
        },
      ),
      style: {
        width: '3rem',
      },
    },
    {
      className: 'overflow-hidden',
      field: 'billGroupId',
      header: 'Bill Group',
      body: nameWithIdBodyTemplate<IContractWithBillGroup, IBillGroupResp>(
        'billGroup',
        { idLinkFn: (id) => getBillGroupPageRoute(id) },
      ),
      style: {
        width: '12.5rem',
      },
    },
    {
      field: 'startDate',
      header: 'Start Date',
      body: dateBodyTemplate<IContractWithBillGroup>('startDate'),
    },
    {
      field: 'endDate',
      header: 'End Date',
      body: dateBodyTemplate<IContractWithBillGroup>('endDate'),
    },
    {
      field: 'endAction',
      header: 'End Action',
      body: statusBodyTemplate<IContractWithBillGroup, ContractEndActionEnum>(
        'endAction',
        CONTRACTS.CONTRACT_END_ACTION_UI_DISPLAY,
      ),
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      className: 'table-cell-sm',
      body: (data: IContractWithBillGroup) =>
        data.renewed ? (
          <MTag variant="purple">Renewed</MTag>
        ) : (
          statusBodyTemplate<IContractWithBillGroup, ContractStatusEnum>(
            'status',
            CONTRACTS.CONTRACT_STATUS_DISPLAY,
          )(data)
        ),
      sortable: true,
    },
    {
      field: 'totalValue',
      header: 'Amount',
      body: totalBodyTemplate,
      sortable: true,
      style: { textAlign: 'right' },
    },
    { field: 'action', header: '', sortable: false, body: actionBodyTemplate },
  ];

  const filterComponentReset = React.useRef<any>(null);

  const hasFilerApplied = filters && filters?.length > 0;
  const hasContent = listData?.content && listData.content.length > 0;
  const shouldShowHeaderContent = hasContent || hasFilerApplied;

  return (
    <MBox>
      <MPageHeader title="Contracts" hideContent={!shouldShowHeaderContent}>
        <MFlex>
          <MHStack spacing="2">
            <MDataTableFilter
              filters={filters}
              filterOptions={CONTRACT_FILTER_OPTIONS_FOR_ACCOUNT_DETAIL}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="accountContracts"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              getFilename={() => `contracts-${accountId}.csv`}
            />
          </MHStack>
        </MFlex>
      </MPageHeader>
      <MDataTable
        value={listData?.content}
        totalRecords={listData?.totalElements}
        totalPages={listData?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no contracts here.',
          btnLabel: 'View Quotes',
          to: getAccountQuotesRoute(accountId),
        }}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
        }}
        loading={loading}
        columns={columns}
        onSelectionChange={(e) =>
          onSelectRow(e.value as IContractWithBillGroup)
        }
      />
      {isOpenRenewalModal && (
        <RenewalModal
          contract={contract}
          open={undefined}
          onClose={onCloseRenewalModal}
          onRenew={undefined}
        />
      )}
      <Outlet />
    </MBox>
  );
};

export default ContractListAccount;
