import { Tab } from '@chakra-ui/react';
import { FC, useRef } from 'react';
import {
  MBox,
  MButton,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MStack,
  MTabList,
  MTabPanel,
  MTabPanels,
  MTabs,
} from '../../../../../components/Monetize';
import { useACL } from '../../../../../services/acl/acl';
import {
  DEFAULT_PAGER,
  IQuoteContacts,
  IQuoteRespSchema,
  QuoteSettingsDefaultAddressSourceEnum,
  SigningOrderEnum,
  TDataTablePager,
} from '../../../../../types';
import { QuoteContactAdditionalRecipients } from './QuoteContactAdditionalRecipients';
import QuoteContactsContactList from './QuoteContactsContactList';

import { useQuoteContext } from '../../quoteContext';
import QuoteContactsAddressList from './QuoteContactsAddressList';

// Jest was constantly re-rendering when arrays were defined inline
const defaultResponse: any[] = [];

const accountContactsPager: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'fullName',
  sortOrder: 1,
  rows: 100,
};

interface QuoteContactsDrawerProps {
  quote: IQuoteRespSchema;
  isReadOnly?: boolean;
  accountId: string;
  quoteContacts: IQuoteContacts;
  // temporary display of signingOrder after user has made a change but before API has responded
  // null: no change is in flight, display value from quote object
  signingOrderUI: SigningOrderEnum | null;
  onClose: (didChange?: boolean) => void;
  updateQuoteSigningOrder: (signingOrder: SigningOrderEnum) => void;
}

const QuoteContactsDrawer: FC<QuoteContactsDrawerProps> = ({
  quote,
  isReadOnly,
  accountId,
  quoteContacts: initialQuoteContacts,
  signingOrderUI,
  onClose,
  updateQuoteSigningOrder,
}: QuoteContactsDrawerProps) => {
  const cancelBtnRef = useRef(null);
  const { quoteContacts } = useQuoteContext();

  const {
    isLoading,
    externalContacts,
    internalContacts,
    externalAccountContacts,
    internalAccountContacts,
    additionalRecipientSelectedContacts,
    didSave,
    handleToggle,
    handleRemove,
    handleUpdatedContact,
    handleNewContact,
    handleContactSelectionChange,
    handleSelectedAdditionalRecipients,
    handleCreatedNewAdditionalContact,
    handleAdditionalContactRemove,
    addressDataForm,
    onSaveAddress,
    addressSource,
    isDisabled,
    isDisabledPrimaryBilling,
    isDisabledEsign,
    isEsignEnabled,
    loadingContacts,
    loadingInternalEsignContacts,
  } = quoteContacts;

  const { canDo } = useACL();

  function handleClose() {
    onClose(didSave);
  }

  function handleTabChange() {}

  return (
    <MDrawer
      isOpen
      placement="right"
      onClose={handleClose}
      size="md"
      initialFocusRef={cancelBtnRef}
      onEsc={handleClose}
      onOverlayClick={handleClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      blockScrollOnMount={false}
    >
      <MDrawerOverlay />
      <MDrawerContent>
        <MDrawerCloseButton />
        <MDrawerHeader>Contacts</MDrawerHeader>
        <MDrawerBody
          p={0}
          bg="tGray.sidebarDark"
          data-testid="contact-drawer-body"
        >
          <MTabs
            variant="line"
            size="sm"
            backgroundColor="tWhite.base"
            defaultIndex={0}
            onChange={handleTabChange}
          >
            <MTabList px={6} pb={2}>
              <Tab data-testid="contacts-contact-tab">Contacts</Tab>
              {addressSource ===
                QuoteSettingsDefaultAddressSourceEnum.ACCOUNT && (
                <Tab data-testid="contacts-address-tab">Addresses</Tab>
              )}
              {isEsignEnabled && quote.requiresEsign && (
                <Tab data-testid="contacts-esign-tab">eSign</Tab>
              )}
            </MTabList>
            <MTabPanels p={2} bg="tGray.sidebarDark">
              <MTabPanel p={0} data-testid="contacts-contact-content">
                <MStack>
                  <QuoteContactsContactList
                    isDisabled={isDisabled}
                    isDisabledPrimaryBilling={isDisabledPrimaryBilling}
                    isDisabledEsign={isDisabledEsign}
                    isEsignEnabled={isEsignEnabled}
                    loadingContacts={loadingContacts}
                    accountId={accountId}
                    accountContacts={externalAccountContacts}
                    contacts={externalContacts}
                    isInternal={false}
                    onToggle={handleToggle}
                    onRemove={handleRemove}
                    onAddContact={handleContactSelectionChange}
                    onUpdateContact={handleUpdatedContact}
                    onNewContact={handleNewContact}
                    addressFormat={quote.fromCompany.addressFormat}
                    canUpdateContact={canDo([['account_contacts', 'update']])}
                    canCreateContact={canDo([['account_contacts', 'create']])}
                    addressDataForm={addressDataForm}
                    onSaveAddress={onSaveAddress}
                  />
                </MStack>
              </MTabPanel>
              {addressSource ===
                QuoteSettingsDefaultAddressSourceEnum.ACCOUNT && (
                <MTabPanel
                  p={0}
                  bg="tGray.sidebarDark"
                  data-testid="contacts-esign-content"
                >
                  <QuoteContactsAddressList
                    isDisabled={isDisabled}
                    accountId={accountId}
                    addressDataForm={addressDataForm}
                    addressFormat={quote.fromCompany.addressFormat}
                  />
                </MTabPanel>
              )}
              <MTabPanel
                p={0}
                bg="tGray.sidebarDark"
                data-testid="contacts-esign-content"
              >
                <MStack>
                  <QuoteContactsContactList
                    isDisabled={isDisabled}
                    isEsignEnabled={isEsignEnabled}
                    loadingContacts={loadingInternalEsignContacts}
                    accountId={accountId}
                    accountName={quote.accountName}
                    accountContacts={internalAccountContacts}
                    contacts={internalContacts}
                    legalEntityName={quote.fromCompany.name || ''}
                    isInternal
                    signingOrder={signingOrderUI || quote.signingOrder}
                    onToggle={handleToggle}
                    onRemove={handleRemove}
                    onAddContact={handleContactSelectionChange}
                    onUpdateContact={handleUpdatedContact}
                    onNewContact={handleNewContact}
                    onChangeSigningOrder={updateQuoteSigningOrder}
                    addressFormat={quote.fromCompany.addressFormat}
                    canUpdateContact={canDo([['account_contacts', 'update']])}
                    canCreateContact={canDo(
                      [['account_contacts', 'create']],
                      true,
                    )}
                    addressDataForm={addressDataForm}
                    onSaveAddress={onSaveAddress}
                  />

                  <QuoteContactAdditionalRecipients
                    isDisabled={isEsignEnabled}
                    accountId={accountId}
                    loadingContacts={loadingInternalEsignContacts}
                    contacts={externalAccountContacts}
                    selectedContacts={additionalRecipientSelectedContacts}
                    selectedExternalContacts={externalContacts}
                    onSelectedContactsChange={
                      handleSelectedAdditionalRecipients
                    }
                    handleCreatedNewContact={handleCreatedNewAdditionalContact}
                    onRemoveAdditionalContact={handleAdditionalContactRemove}
                  />
                </MStack>
              </MTabPanel>
            </MTabPanels>
          </MTabs>
        </MDrawerBody>

        <MDivider />

        <MDrawerFooter>
          <MBox w="full">
            <MFlex justifyContent="center">
              <MButton
                ref={cancelBtnRef}
                data-testid="contact-drawer-close-btn"
                onClick={() => handleClose()}
                isLoading={isLoading}
                variant="cancel"
              >
                Close
              </MButton>
            </MFlex>
          </MBox>
        </MDrawerFooter>
      </MDrawerContent>
    </MDrawer>
  );
};

export default QuoteContactsDrawer;
