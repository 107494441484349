import { NumberInputProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatCurrency } from '@monetize/utils/core';
import isNaN from 'lodash/isNaN';
import React, { FunctionComponent as FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import MButton from './MButton';
import MCenterModal from './MCenterModal';
import { MCurrencyInput } from './MCurrencyInput';
import MFormField from './MFormField';
import MText from './MText';
import { MBox, MFlex, MFormLabel, MHStack, MStack } from './chakra';

export interface DiscountTargetPriceModalAmountOptions {
  amountWithoutDiscount: number;
  billingFrequencyInMonths: number;
  contractLength: number;
  currency: string;
  discountPercent: number;
  offeringPeriod: string;
  productName: string;
  targetAmount: number;
}

export interface DiscountTargetPriceModalProps extends NumberInputProps {
  targetAmountOptions: DiscountTargetPriceModalAmountOptions;
  isOpen: boolean;
  onClose: () => void;
  onChangeValue?: (value: number) => void;
}

export const SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION = {
  title: 'Target Amount',
  value: 'SET_TARGET_VALUE',
};

export const DiscountSetTargetModalSchema = z.object({
  amount: z.number().min(1),
});

export type IDiscountSetTargetModalSchema = z.infer<
  typeof DiscountSetTargetModalSchema
>;

const DiscountTargetPriceModal: FC<DiscountTargetPriceModalProps> =
  React.forwardRef<any, DiscountTargetPriceModalProps>(
    (
      {
        targetAmountOptions,
        isOpen,
        onClose,
        onChangeValue,
      }: DiscountTargetPriceModalProps,
      ref: any,
    ) => {
      const {
        amountWithoutDiscount,
        billingFrequencyInMonths,
        contractLength,
        currency,
        discountPercent,
        offeringPeriod,
        productName,
        targetAmount,
      } = targetAmountOptions;

      const showTargetAmountWarning = contractLength > billingFrequencyInMonths;

      const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        reset,
        watch,
      } = useForm<IDiscountSetTargetModalSchema>({
        resolver: zodResolver(
          DiscountSetTargetModalSchema.extend({
            amount: z
              .union([z.string(), z.number()])
              .transform((value, ctx) => {
                const floatNum = parseFloat(value.toString());
                if (isNaN(floatNum)) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Enter a number',
                  });
                  return z.NEVER;
                }

                if (floatNum < 0) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Number must be greater than or equal to 0',
                  });
                }
                if (floatNum > amountWithoutDiscount) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Target Amount must be less than or equal to un-discounted Amount of ${formatCurrency(
                      amountWithoutDiscount.toFixed(2),
                      { currency },
                    )}`,
                  });
                }
                return floatNum;
              }),
          }),
        ),
        mode: 'onChange',
        defaultValues: { amount: 0 },
      });

      useEffect(() => {
        if (isOpen) {
          reset({ amount: targetAmount });
          setDiscount(discountPercent);
        }
      }, [isOpen, reset]);

      const [discount, setDiscount] = useState(0);
      const watchAmount = watch('amount');

      useEffect(() => {
        if (
          watchAmount &&
          watchAmount > 0 &&
          watchAmount < amountWithoutDiscount
        ) {
          setDiscount(
            ((amountWithoutDiscount - watchAmount) / amountWithoutDiscount) *
              100,
          );
        }
        if (watchAmount === amountWithoutDiscount) {
          setDiscount(0);
        }
      }, [amountWithoutDiscount, watchAmount]);

      const onSubmit = async (requestData: IDiscountSetTargetModalSchema) => {
        try {
          onChangeValue && onChangeValue(discount);
          onClose();
        } catch (err) {
          // handleApiErrorToast(err);
        }
      };

      if (!productName) {
        return null;
      }

      return (
        <MCenterModal
          size="md"
          isOpen={isOpen}
          onClose={onClose}
          modalTitle={'Set Target Amount'}
          renderFooter={() => (
            <MStack
              spacing={4}
              direction="row"
              align="center"
              justify="right"
              flex={1}
            >
              <MButton onClick={onClose} variant="cancel" minW="auto">
                Cancel
              </MButton>
              <MButton
                variant="primary"
                onClick={handleSubmit(onSubmit)}
                isDisabled={!isValid}
                type="submit"
                minW="auto"
              >
                Save
              </MButton>
            </MStack>
          )}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <MFlex flexDir="column" gap={4}>
              <MText>
                Set the amount to be charged for {offeringPeriod}. <br /> The
                discount will be automatically calculated.
              </MText>

              <MBox>
                <MFormLabel>Product Name</MFormLabel>
                <MText>{productName}</MText>
              </MBox>

              <MHStack alignItems="flex-start" gap={6}>
                <MFormField
                  label="Target Amount"
                  maxWidth={'120px'}
                  isInvalid={!!errors.amount?.message}
                >
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                      <MCurrencyInput
                        inputMode="decimal"
                        precision={2}
                        onChange={(
                          valueAsString: string,
                          valueAsNumber: number,
                        ) => {
                          if (valueAsNumber > targetAmount) {
                            setDiscount(0.0);
                          }
                          onChange(valueAsString);
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
                <MFormField label="Discount">
                  <MText mt={1}>{discount.toFixed(2)}%</MText>
                </MFormField>
              </MHStack>
              <MBox minH="42px" mt={-2}>
                <MText color="tRed.base" fontSize="sm">
                  {errors?.amount?.message}
                </MText>
                {showTargetAmountWarning && (
                  <MText color="tOrange.tangerine" fontSize="sm">
                    Upon Save, the Target Amount may be adjusted slightly to
                    create a billing schedule with equal payments.
                  </MText>
                )}
              </MBox>
            </MFlex>
          </form>
        </MCenterModal>
      );
    },
  );

export default DiscountTargetPriceModal;
