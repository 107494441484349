import { addDays } from 'date-fns/addDays';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import { MdTimer } from 'react-icons/md';
import { NavigateFunction } from 'react-router-dom';
import {
  formatCurrency,
  getDecimalPointsLength,
  isProrationDisplayable,
  sortByProductType,
} from '.';
import {
  MAvatar,
  MFlex,
  MIcon,
  MIDCopyBox,
  MText,
  MTooltip,
} from '../components/Monetize';
import QuoteStatus from '../components/Quotes/QuoteStatus';
import { ROUTES } from '../constants';
import { RATE_BILLING_FREQUENCY_SHORTCODE_DISPLAY } from '../constants/offerings';
import {
  ONE_TIME_PRODUCT_TYPES,
  VARIABLE_AMOUNT_PRODUCT_TYPES,
} from '../constants/products';
import { QuoteStatusTagStyle, QuoteTypeEnumDisplay } from '../constants/quotes';
import { logger } from '../services/logger';
import {
  AmountUnitTypeEnum,
  IContract,
  IContractWithQuotes,
  IQuoteItemReqSchema,
  IQuoteItemRespSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuotePrice,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IRateResBaseSchema,
  IRateResSchema,
  ProductTypeEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteItemReqSchema,
  QuoteOfferingReqSchema,
  QuoteOfferingWithChildren,
  QuoteRequestSchema,
  QuoteStatusEnum,
  QuoteTypeEnum,
  RateBillingFrequencyEnum,
} from '../types';
import { toDateTimeShort } from './dates';

/**
 * Sort QuoteItems for each offering on a quote
 * This mutates provided data
 */
export const sortQuoteItems = (quote: IQuoteRespSchema): IQuoteRespSchema => {
  try {
    quote?.quoteOfferings?.forEach((offering) => {
      if (Array.isArray(offering.items)) {
        offering.items = sortByProductType(offering.items);
      }
    });
  } catch (ex) {
    logger.warn('Failed to sort quote items', ex);
  }
  return quote;
};

export const editQuote = (
  navigate: NavigateFunction,
  quoteId: string,
  openInNewWindow = false,
) => {
  openInNewWindow
    ? window.open(ROUTES.getQuoteEditRoute(quoteId), '_blank')
    : navigate(ROUTES.getQuoteEditRoute(quoteId));
};

export const reviewQuote = (
  navigate: NavigateFunction,
  quoteId: string,
  openInNewWindow = false,
) => {
  openInNewWindow
    ? window.open(ROUTES.getQuoteReviewRoute(quoteId), '_blank')
    : navigate(ROUTES.getQuoteReviewRoute(quoteId));
};

export const openQuote = (
  navigate: NavigateFunction,
  quoteId: string,
  status?: QuoteStatusEnum,
  openInNewWindow = false,
) => {
  if (!status) {
    openInNewWindow
      ? window.open(ROUTES.getQuoteBaseRoute(quoteId), '_blank')
      : navigate(ROUTES.getQuoteBaseRoute(quoteId));
  } else if (status === QuoteStatusEnum.DRAFT) {
    editQuote(navigate, quoteId, openInNewWindow);
  } else {
    reviewQuote(navigate, quoteId, openInNewWindow);
  }
};

export const loadTimerIcon = (rowData: IQuoteRespSchema) => {
  const { status, approvalSubmittedAt, expirationDate } = rowData;
  if (
    status === QuoteStatusEnum.REVIEW &&
    approvalSubmittedAt &&
    isBefore(parseISO(approvalSubmittedAt), addDays(new Date(), -4))
  ) {
    return {
      icon: <MIcon as={MdTimer} w={4} h={5} ml={2} color="tPurple.safety" />,
      tooltip: 'Quote in an approval process for a long time',
    };
  }
  if (
    status === QuoteStatusEnum.SENT &&
    expirationDate &&
    isBefore(parseISO(expirationDate), addDays(new Date(), 10))
  ) {
    return {
      icon: <MIcon as={MdTimer} w={4} h={5} ml={2} color="tOrange.base" />,
      tooltip: 'Quote will expire soon',
    };
  }

  return {
    icon: '',
    tooltip: '',
  };
};

export const quoteOwnerBodyTemplate = (rowData: IQuoteRespSchema) => (
  <MFlex justify="center" align="center">
    <MTooltip
      shouldWrapChildren
      label={rowData.ownerName}
      placement="bottom-start"
    >
      <MAvatar name={rowData.ownerName} mr="0" />
    </MTooltip>
  </MFlex>
);

export const quoteIdBodyTemplate = (rowData: IQuoteRespSchema) => (
  <MTooltip label={rowData.id} placement="bottom-end">
    <MIDCopyBox copyValue={rowData.id} displayIcon={false} />
  </MTooltip>
);

export const timerTemplate = (rowData: IQuoteRespSchema) => {
  const timer = loadTimerIcon(rowData);
  return (
    <MTooltip label={timer.tooltip} placement="bottom-end">
      <MText>{timer.icon}</MText>
    </MTooltip>
  );
};

export const companyBodyTemplate = (rowData: IQuoteRespSchema) => (
  <MTooltip label="View Quote" placement="bottom-end">
    <MText noOfLines={1} title={rowData.accountName || ''}>
      {rowData.accountName ?? '-'}
    </MText>
  </MTooltip>
);

export const lastUpdateBodyTemplate = (rowData: IQuoteRespSchema) =>
  rowData.modifyDate && (
    <MText noOfLines={1}>{toDateTimeShort(rowData.modifyDate)}</MText>
  );

export const totalBodyTemplate = (rowData: IQuoteRespSchema) => (
  <MTooltip label={rowData.currency} placement="bottom-end">
    <MText noOfLines={1}>
      {formatCurrency(rowData.amount, { currency: rowData.currency })}
    </MText>
  </MTooltip>
);

export const statusBodyTemplate = (rowData: IQuoteRespSchema) => {
  if (rowData.status) {
    return <QuoteStatus status={rowData.status} />;
  }
  return null;
};
export const quoteStatusStyle = (rowDate: IQuoteRespSchema) => {
  const { status } = rowDate;

  const style = status
    ? QuoteStatusTagStyle[status as QuoteStatusEnum]
    : {
        color: 'tGray.darkGrayPurple',
        bgColor: 'transparent',
      };
  return style;
};
export const typeBodyTemplate = (rowData: IQuoteRespSchema) =>
  rowData.type && (
    <MText noOfLines={1}>{QuoteTypeEnumDisplay[rowData.type]}</MText>
  );

export const getIsCustomPriceUsed = (quote: IQuoteRespSchema): boolean =>
  quote.quoteOfferings
    ?.flatMap(({ items }) => items)
    .some(({ customPriceId }) => customPriceId);

export const getCustomRateNames = (rates: IRateResSchema[]) =>
  rates.reduce<{ rateName: string; offeringName: string }[]>((res, rate) => {
    if (rate.parentRateId) {
      res.push({ rateName: rate.name, offeringName: rate.offering.name });
    }
    return res;
  }, []);

export const hasVariableAmountProduct = (
  quote?: IQuoteRespSchema | null,
): boolean => {
  if (!quote?.quoteOfferings) {
    return false;
  }
  return quote.quoteOfferings.some(({ items }) =>
    items.some(
      ({ productType, amendmentStatus }) =>
        VARIABLE_AMOUNT_PRODUCT_TYPES.has(productType) &&
        amendmentStatus !== QuoteItemAmendmentStatusEnum.REMOVED,
    ),
  );
};

/**
 * Converts quote offerings into a list of root parent offerings and their children,
 * based on parentQuoteOfferingId.
 *
 * All children will be sorted based on their startDate.
 *
 * @param quoteOfferings Pre-sorted quote offerings
 * @returns
 */
export const getQuoteOfferingsWithChildren = (
  quoteOfferings: IQuoteOfferingRespSchema[],
) => {
  const quoteOfferingsWithChildren = Object.values(
    (quoteOfferings || []).reduce(
      (output: Record<string, QuoteOfferingWithChildren>, quoteOffering) => {
        if (!quoteOffering.parentQuoteOfferingId) {
          output[quoteOffering.id] = output[quoteOffering.id] || {
            quoteOffering: quoteOffering,
            children: [],
          };
          output[quoteOffering.id].quoteOffering = quoteOffering;
        } else {
          output[quoteOffering.parentQuoteOfferingId] = output[
            quoteOffering.parentQuoteOfferingId
          ] || {
            quoteOffering: null,
            children: [],
          };
          output[quoteOffering.parentQuoteOfferingId].children.push(
            quoteOffering,
          );
        }
        return output;
      },
      {},
    ),
  );

  quoteOfferingsWithChildren
    .filter(({ quoteOffering }) => !!quoteOffering)
    .forEach((quoteOffering) => {
      quoteOffering.children = orderBy(quoteOffering.children, ['startDate']);
    });

  return quoteOfferingsWithChildren;
};

/**
 * Doing reverse what getQuoteOfferingsWithChildren does
 * Get quote offerings from parent offerings
 *
 * @param quoteOfferingsWithChildren QuoteOfferingWithChildren[]
 * @returns QuoteOffering[]
 */
export const getQuoteOfferingsFromParentOfferings = (
  quoteOfferingsWithChildren: QuoteOfferingWithChildren[],
) => {
  const quoteOfferings: IQuoteOfferingRespSchema[] = [];

  quoteOfferingsWithChildren.map((quoteOfferingWithChildren) => {
    const { children, ...quoteOffering } = quoteOfferingWithChildren;
    quoteOfferings.push(quoteOffering.quoteOffering);

    children.map((child) => {
      quoteOfferings.push(child);
    });
  });

  return quoteOfferings;
};

/**
 * Check if target is included in same quote offering group with source
 * @param sourceQuoteOffering Source quote offering
 * @param targetQuoteOffering Target one to check against source
 * @returns If same quote offering group, true, else false
 */
export const isSameQuoteOfferingGroup = (
  sourceQuoteOffering: IQuoteOfferingRespSchema,
  targetQuoteOffering: IQuoteOfferingRespSchema,
) => {
  if (sourceQuoteOffering.parentQuoteOfferingId) {
    return (
      targetQuoteOffering.parentQuoteOfferingId ===
        sourceQuoteOffering.parentQuoteOfferingId ||
      targetQuoteOffering.id === sourceQuoteOffering.parentQuoteOfferingId
    );
  } else {
    return (
      targetQuoteOffering.id === sourceQuoteOffering.id ||
      targetQuoteOffering.parentQuoteOfferingId === sourceQuoteOffering.id
    );
  }
};

/**
 * Return updated quote offerings. From new quote offerings, update only quote offerings within same quote offering group of actionQuoteOffering
 * @param oldQuoteOfferings existing quote offerings
 * @param newQuoteOfferings new quote offerings
 * @param actionQuoteOffering  quote offering that action was performed
 * @returns
 */
export const getUpdatedQuoteOfferingGroup = (
  oldQuoteOfferings: IQuoteOfferingRespSchema[],
  newQuoteOfferings: IQuoteOfferingRespSchema[],
  actionQuoteOffering: IQuoteOfferingRespSchema,
) => {
  return newQuoteOfferings.map((item) => {
    if (isSameQuoteOfferingGroup(actionQuoteOffering, item)) {
      return item;
    } else {
      return (
        oldQuoteOfferings.find((currentItem) => currentItem.id === item.id) ||
        item
      );
    }
  });
};
/**
 * Check if quote offering is added to amendment quote or not
 * @param quoteOffering QuoteOffering
 * @returns
 */
export const isQuoteOfferingAddedToAmendment = (
  quoteOffering: IQuoteOfferingRespSchema,
) => {
  return quoteOffering?.items?.every((item) => {
    return item.amendmentStatus === QuoteItemAmendmentStatusEnum.ADDED;
  });
};

export const getShortCodeForUnitPriceFrequency = (
  displayUnitPriceFrequency?: number,
) => {
  if (!displayUnitPriceFrequency || displayUnitPriceFrequency === 1) {
    return 'mo';
  }
  if (displayUnitPriceFrequency === 12) {
    return 'yr';
  }
  if (displayUnitPriceFrequency % 12 === 0) {
    return `${displayUnitPriceFrequency / 12}yr`;
  }
  return `${displayUnitPriceFrequency}mo`;
};

export const getPricePerUnitWithRateFrequencyShortCode = ({
  priceStr,
  productType,
  isCustomPrice,
  item,
  offeringRate,
}: {
  priceStr: string;
  productType?: ProductTypeEnum;
  isCustomPrice?: boolean;
  item?: Pick<IQuoteItemRespSchema, 'options' | 'productId'>;
  offeringRate?: Pick<
    IRateResSchema,
    | 'billingFrequency'
    | 'billingFrequencyInMonths'
    | 'currency'
    | 'usageBillingFrequency'
    | 'prices'
  > | null;
}): string => {
  const { billingFrequency, billingFrequencyInMonths } = offeringRate || {
    billingFrequency: null,
    billingFrequencyInMonths: null,
    usageBillingFrequency: null,
    prices: [],
  };
  const { options } = item || {
    options: null,
    productId: '',
  };

  if (
    !billingFrequency ||
    (productType && ONE_TIME_PRODUCT_TYPES.has(productType))
  ) {
    return priceStr;
  }

  if (productType === ProductTypeEnum.USAGE) {
    return priceStr === '*' ? '*/mo' : `${priceStr}/unit`;
  }

  const shortCode =
    RATE_BILLING_FREQUENCY_SHORTCODE_DISPLAY[billingFrequency](
      billingFrequencyInMonths,
    ) || '';
  if (options && options.displayUnitPriceFrequency) {
    return `${priceStr}/${getShortCodeForUnitPriceFrequency(
      options.displayUnitPriceFrequency,
    )}`;
  } else if (shortCode) {
    return `${priceStr}/${shortCode}`;
  } else {
    return priceStr;
  }
};

export const getQuoteColumnAvailabilityReview = (
  productType: ProductTypeEnum,
) => {
  // Disable discount for MIN_COMMIT and ONETIME_PREPAID_CREDIT
  const isDiscountNotAvailable = [
    ProductTypeEnum.MIN_COMMIT,
    ProductTypeEnum.ONETIME_PREPAID_CREDIT,
  ].includes(productType);

  // Disable quantity for USAGE and MIN_COMMIT
  const isQuantityNotAvailable = [ProductTypeEnum.USAGE].includes(productType);

  return { isDiscountNotAvailable, isQuantityNotAvailable };
};

export const getQuoteColumnAvailability = (productType: ProductTypeEnum) => {
  // Disable discount for MIN_COMMIT and ONETIME_PREPAID_CREDIT
  const isDiscountNotAvailable = [
    ProductTypeEnum.MIN_COMMIT,
    ProductTypeEnum.ONETIME_PREPAID_CREDIT,
  ].includes(productType);

  // Disable quantity for USAGE and MIN_COMMIT
  const isQuantityNotAvailable = [
    ProductTypeEnum.USAGE,
    ProductTypeEnum.MIN_COMMIT,
  ].includes(productType);

  // Disable quantity for MIN_COMMIT
  const isPPUNotAvailable = [ProductTypeEnum.MIN_COMMIT].includes(productType);

  return { isDiscountNotAvailable, isQuantityNotAvailable, isPPUNotAvailable };
};

export const getQuoteOfferingDiscountAvailability = (
  quoteOffering?: IQuoteOfferingRespSchema | null,
) => {
  if (!quoteOffering) {
    return {
      isQuoteOfferingDiscountAvailable: false,
    };
  }

  const isQuoteOfferingDiscountAvailable = !(
    quoteOffering.items.length === 1 &&
    (quoteOffering.items[0].productType === ProductTypeEnum.MIN_COMMIT ||
      quoteOffering.items[0].productType ===
        ProductTypeEnum.ONETIME_PREPAID_CREDIT)
  );
  return { isQuoteOfferingDiscountAvailable };
};

export const getVariableAmountIndicator = (variableAmount?: boolean): string =>
  variableAmount ? '*' : '';

// NOTE: this logic is also used in the html-to-pdf service in the platform repo (the same function name in that repo
// should be a copy of this function), so .pdfs display the same as Edit Quote and Review Quote
export const getProrationAndCredit = (
  item: IQuoteItemRespSchema,
): { credit: number | null; proration: number | null } => {
  let credit: number | null = null,
    proration: number | null = null;

  if (item.credit === 0) {
    proration = item.debitProrationMultiplier;
  } else {
    ({ credit } = item);
    if (item.debit === 0) {
      proration = item.creditProrationMultiplier;
    } else {
      proration = item.debitProrationMultiplier;
    }
  }

  if (!isProrationDisplayable(proration, item.productType)) {
    proration = null;
  }

  return {
    credit,
    proration,
  };
};

export function sortQuoteApprovals<
  T extends { rank: number; createDate: string | null },
>(approvals: T[]) {
  return (
    approvals?.sort((v1, v2) => {
      if (v1.rank === v2.rank) {
        return (
          new Date(v1.createDate || '').getTime() -
          new Date(v2.createDate || '').getTime()
        );
      }
      return v1.rank - v2.rank;
    }) || []
  );
}

/**
 * Get the name of the first approver
 * @param quote quote object
 * @returns name of the first quote approver
 */
export const whoIsFirstQuoteApproval = (quote: IQuoteRespSchema | null) => {
  if (
    !quote ||
    !quote.approvals ||
    !Array.isArray(quote.approvals) ||
    quote.approvals.length === 0
  ) {
    return '';
  }

  const [approval] = sortQuoteApprovals(quote.approvals);
  return approval.teamId ? approval.teamName : approval.username;
};

/**
 * Transform IQuoteRespSchema into IQuoteRequestSchema
 */
export const getQuoteRequestFromQuoteResponse = (
  quote: IQuoteRespSchema,
): IQuoteRequestSchema => {
  const quoteRequest = pick(
    quote,
    Object.keys(QuoteRequestSchema.shape),
  ) as IQuoteRequestSchema;

  /** Set all other fields that differ between data structures */
  quoteRequest.opportunityId = quote.opportunity?.id;

  return quoteRequest;
};

/**
 *
 * Based on the customContractLengthInputVisibility, amendment, renewal
 * it returns gridTemplateColumns
 */
export function getQuoteFormHeaderGridTemplateColumns({
  isAmendment,
  isRenewal,
  useAmendmentV2,
}: {
  isAmendment: boolean;
  isRenewal: boolean;
  useAmendmentV2: boolean;
}) {
  if (isAmendment) {
    if (!useAmendmentV2) {
      return '7.875rem minmax(10rem, 12.5rem) 10rem 9.375rem 6rem 9.75rem 3.75rem';
    } else {
      return 'minmax(10rem, 12.5rem) 10rem 9.375rem 6rem 9.75rem 3.75rem';
    }
  }
  return `minmax(10rem, 12.5rem) 10rem 9.375rem 6rem 9.75rem 3.75rem`;
}

/**
 * Determine all the fields that have been modified in a quote offering
 * By comparing the payload and the existing state
 *
 * Certain fields on the form don't have reliable dirty detection and other fields have complex data to determine if modified
 * This function handles all of that complexity
 *
 * @param newOffering
 * @param existingQuoteOffering
 * @param overrideOfferingDirtyFields Header level (offering) fields that should be marked as dirty even if same
 *                             This is generally used for changes to related entities, such as Rate
 */
export function calculateModifiedQuoteItemFields(
  newOffering: IQuoteOfferingReqSchema,
  existingQuoteOffering: IQuoteOfferingRespSchema,
  overrideOfferingDirtyFields?: string[],
  allowOptionalProducts?: boolean,
): Map<string, Set<string>> {
  try {
    const output = new Map<string, Set<string>>([
      ['header', new Set()], // QuoteOffering fields
      ...newOffering.items.map((item): [string, Set<string>] => [
        item.id!,
        new Set<string>(),
      ]),
    ]);

    if (overrideOfferingDirtyFields) {
      overrideOfferingDirtyFields.forEach((field) =>
        output.get('header')?.add(field),
      );
    }

    /**
     * Compare quote offering fields to see if anything is dirty
     */
    quoteOfferingComparison.offering.fieldsToCompare.forEach((field) => {
      compareField(
        output.get('header')!,
        newOffering,
        existingQuoteOffering,
        field,
        quoteOfferingComparison.offering.comparisonFns,
      );
    });

    /**
     * Triggering dirty quote item fields for optional products removal
     */

    if (
      allowOptionalProducts &&
      newOffering.items.length < existingQuoteOffering.items.length
    ) {
      existingQuoteOffering.items.forEach((existingItem) => {
        const newQuoteItem = newOffering.items.find(
          ({ id }) => id === existingItem.id,
        );
        if (isUndefined(newQuoteItem)) {
          output.set(
            existingItem.id,
            new Set<string>([...quoteOfferingComparison.item.fieldsToCompare]),
          );
        }
      });
      return output;
    }

    /**
     * Compare quote item fields to see if anything is dirty
     */
    newOffering.items.forEach((newQuoteItem) => {
      const existingItem = existingQuoteOffering.items.find(
        ({ id }) => id === newQuoteItem.id,
      );
      quoteOfferingComparison.item.fieldsToCompare.forEach((field) => {
        compareField(
          output.get(newQuoteItem.id!)!,
          newQuoteItem,
          existingItem,
          field,
          quoteOfferingComparison.item.comparisonFns,
        );
      });
    });
    return output;
  } catch (ex) {
    logger.warn('Error calculating quoteOfferingComparison', ex);
    return new Map();
  }
}

/**
 * BP-6928
 * Determine if a quote offering is allowed to make a specific rate change
 * For scheduled changes, the rate is not allowed to change billing frequency
 *
 * @param ratesById
 * @param oldRateId
 * @param newRateId
 * @param isPartOfScheduledChange
 * @returns
 */
export function isOfferingRateChangeAllowed({
  ratesById,
  oldRateId,
  newRateId,
  isPartOfScheduledChange,
}: {
  ratesById: Record<string, IRateResBaseSchema>;
  oldRateId: string;
  newRateId: string;
  isPartOfScheduledChange: boolean;
}): boolean {
  const oldRate = ratesById[oldRateId];
  const newRate = ratesById[newRateId];
  if (
    !isPartOfScheduledChange ||
    oldRateId === newRateId ||
    !oldRate ||
    !newRate
  ) {
    return true;
  }

  return (
    oldRate.billingFrequencyInMonths === newRate.billingFrequencyInMonths &&
    oldRate.subscriptionTiming === newRate.subscriptionTiming
  );
}

/**
 * Perform comparison of field
 * @param outputSet Set to add dirty fields to
 * @param newObject Quote Offering or Quote Item request
 * @param previousObj Quote Offering or Quote Item response to compare against
 * @param field Field to compare on old an new object
 * @param comparisonFns For complex comparisons, object with field as the key and comparison function as the value
 */
function compareField(
  outputSet: Set<string>,
  newObject: any,
  previousObj: any,
  field: keyof IQuoteOfferingReqSchema | keyof IQuoteItemReqSchema,
  comparisonFns: Record<string, (oldObj: any, newObj: any) => boolean>,
) {
  try {
    comparisonFns = comparisonFns || {};
    const comparisonFn = comparisonFns[field];

    if (!previousObj) {
      // This is a new offering, flag every field as modified
      outputSet?.add(field);
    } else if (comparisonFn) {
      // Call comparisonFn to see if field should be considered as modified
      if (comparisonFn(newObject, previousObj)) {
        outputSet?.add(field);
      }
    } else if (
      // Perform strict equality check to see if field is modified
      newObject[field] !== previousObj[field]
    ) {
      outputSet?.add(field);
    }
  } catch (ex) {
    logger.warn('Error comparing field', field, ex);
  }
}

/**
 * Comparison functions for quote offering and quote item
 * This is used to detect if there are any changes
 * LOGIC:
 * * All fields will be compared unless included in QUOTE_OFFERING_FIELDS_COMPARISON_IGNORE OR QUOTE_ITEM_FIELDS_COMPARISON_IGNORE
 * * Comparison will be a simple comparison using `!==` unless the field has a comparisonFns, in which case that will be used
 */

const QUOTE_OFFERING_FIELDS_COMPARISON_IGNORE = new Set([
  'id', // this will never change
  'offeringId', // this will never change
  'subscriptionId', // this will never change
  'items', // compared separately
]);

const QUOTE_ITEM_FIELDS_COMPARISON_IGNORE = new Set([
  'id', // this will never change
  'customId', // this will never change
  'productId', // this will never change
  'sku', // this will never change
  'customDiscountType', // discounts handled by customDiscountAmountOrPercent
  'customDiscountName', // discounts handled by customDiscountAmountOrPercent
  'customDiscountDescription', // discounts handled by customDiscountAmountOrPercent
]);

/**
 * Comparison object
 * fieldsToCompare -> These are all the fields on the data model to compare to detect if a field changed
 * comparisonFns -> For complex fields, these are comparison functions to be used instead of `!==`
 */
const quoteOfferingComparison = {
  offering: {
    fieldsToCompare: Object.keys(QuoteOfferingReqSchema.shape).filter(
      (field) => !QUOTE_OFFERING_FIELDS_COMPARISON_IGNORE.has(field),
    ) as (keyof IQuoteOfferingReqSchema)[],
    /**
     * IF the field on the request and response cannot be compared with !==
     * then add comparison function here
     */
    comparisonFns: {
      discountIds: (
        newOffering: IQuoteOfferingReqSchema,
        existingQuoteOffering: IQuoteOfferingRespSchema,
      ): boolean => {
        if (!newOffering.discountIds) {
          return false;
        }
        const discountIds = new Set(newOffering.discountIds);
        const modifiedDiscount =
          newOffering.discountIds.length !==
            existingQuoteOffering.discounts.length ||
          existingQuoteOffering.discounts.some(
            (discount) => !discountIds.has(discount.id),
          );
        return modifiedDiscount;
      },
      schedule: (
        newOffering: IQuoteOfferingReqSchema,
        existingQuoteOffering: IQuoteOfferingRespSchema,
      ): boolean => {
        return (
          !!newOffering.schedule &&
          (newOffering.schedule?.startDate !==
            existingQuoteOffering.startDate ||
            newOffering.schedule?.endDate !== existingQuoteOffering.endDate)
        );
      },
    },
  },
  item: {
    fieldsToCompare: Object.keys(QuoteItemReqSchema.shape).filter(
      (field) => !QUOTE_ITEM_FIELDS_COMPARISON_IGNORE.has(field),
    ) as (keyof IQuoteItemReqSchema)[],
    comparisonFns: {
      customDiscountAmountOrPercent: (
        newItem: IQuoteItemReqSchema,
        existingItem: IQuoteItemRespSchema,
      ): boolean => {
        if (
          newItem.customDiscountType !== existingItem.customDiscountType ||
          newItem.customDiscountName !== existingItem.customDiscountName ||
          newItem.customDiscountDescription !==
            existingItem.customDiscountDescription ||
          newItem.customDiscountAmountOrPercent !==
            existingItem.customDiscountAmountOrPercent
        ) {
          return true;
        }
        return false;
      },
      customPrice: (
        newItem: IQuoteItemReqSchema,
        existingItem: IQuoteItemRespSchema,
      ): boolean => {
        return (
          !!newItem.customPrice &&
          newItem.customPrice.unitAmount !== existingItem.unitPrice
        );
      },
      options: (
        newItem: IQuoteItemReqSchema,
        existingItem: IQuoteItemRespSchema,
      ): boolean => {
        const newItemOptions: any = newItem.options || {};
        const existingItemOptions: any = existingItem.options || {};
        return Object.keys(newItem.options || {}).some((option) => {
          return newItemOptions[option] !== existingItemOptions[option];
        });
      },
    },
  },
} as const;

export const hasQuoteDiscount = (quote?: IQuoteRespSchema | null): boolean => {
  if (!quote) {
    return false;
  }
  if (quote.discountAmount) {
    return true;
  }
  const quoteItems = quote.quoteOfferings.flatMap(
    ({ items }) => items,
  ) as IQuoteItemRespSchema[];

  const hasDiscountInQuoteItems = quoteItems.some(
    ({
      customDiscountAmountOrPercent,
      discountAmount,
      discountPercent,
      discounts,
    }) => {
      return (
        Boolean(customDiscountAmountOrPercent) ||
        Boolean(discountAmount) ||
        Boolean(discountPercent) ||
        Boolean(discounts.length)
      );
    },
  );
  return hasDiscountInQuoteItems;
};

export const getUnitPriceBeforeDiscount = (
  item: IQuoteItemRespSchema,
  quotePrices: IQuotePrice[],
  currency: string,
  rateId?: string,
  parentQuoteOfferingId?: string,
): string => {
  if (
    item &&
    !isNil(item.unitPrice) &&
    !isNil(item.unitPriceAfterDiscount) &&
    item.unitPrice - item.unitPriceAfterDiscount > 0
  ) {
    if (item.options && Boolean(item.options.displayUnitPriceFrequency)) {
      return formatCurrency(item.displayUnitPrice, { currency });
    }
    return formatCurrency(item.unitPrice, { currency });
  }
  // For usage, get the discount information from the quote prices
  if (
    item.productType === ProductTypeEnum.USAGE &&
    rateId &&
    quotePrices?.length
  ) {
    const prices = getQuotePricesForQuoteItem({
      parentQuoteOfferingId,
      quoteOfferingId: item.quoteOfferingId,
      quotePrices,
      productId: item.productId,
      rateId,
    });

    if (prices.length === 1 && prices[0].amount && prices[0].discount) {
      return formatCurrency(prices[0].amount, { currency });
    }
  }

  return '';
};

export const getUnitPriceAfterDiscount = ({
  parentQuoteOfferingId,
  item,
  quotePrices,
  currency,
  offeringId,
  rateId,
}: {
  parentQuoteOfferingId?: string;
  item: IQuoteItemRespSchema;
  quotePrices: IQuotePrice[];
  currency: string;
  offeringId?: string;
  rateId?: string;
}) => {
  if (item.options && Boolean(item.options.displayUnitPriceFrequency)) {
    const hasDiscount =
      Boolean(item.customDiscountAmountOrPercent) ||
      Boolean(item.discountAmount) ||
      Boolean(item.discountPercent) ||
      Boolean(item.discounts.length);
    if (hasDiscount) {
      const discountPercent =
        item.customDiscountType === AmountUnitTypeEnum.PERCENTAGE &&
        item.customDiscountAmountOrPercent
          ? item.customDiscountAmountOrPercent
          : item.discountPercent; // discountPercent has less precision, only use if absolutely necessary
      return formatCurrency(
        item.displayUnitPrice - (discountPercent / 100) * item.displayUnitPrice,
        { currency, maximumFractionDigits: 2 },
      );
    }

    return formatCurrency(item.displayUnitPrice, { currency });
  }
  // For usage, get the discount information from the quote prices
  if (item.productType === ProductTypeEnum.USAGE) {
    if (offeringId && rateId) {
      const prices = getQuotePricesForQuoteItem({
        parentQuoteOfferingId,
        quoteOfferingId: item.quoteOfferingId,
        quotePrices,
        productId: item.productId,
        rateId,
      });
      if (prices.length === 1 && prices[0]) {
        const amountDecimalPointLength = getDecimalPointsLength(
          prices[0].amount,
        );
        return formatCurrency(prices[0].amount - (prices[0].discount || 0), {
          currency,
          maximumFractionDigits: Math.max(2, amountDecimalPointLength ?? 2),
          minimumFractionDigits: 2,
        });
      }
    }
    return '*';
  }
  return formatCurrency(item.unitPriceAfterDiscount, { currency });
};

export const getDisplayUnitPriceFrequencyDefaultValue = (
  billingFrequency: RateBillingFrequencyEnum,
  displayUnitPriceFrequency?: number | null,
): number => {
  const defaultFrequencyMap: Record<RateBillingFrequencyEnum, number> = {
    [RateBillingFrequencyEnum.MONTHLY]: 1,
    [RateBillingFrequencyEnum.QUARTERLY]: 3,
    [RateBillingFrequencyEnum.SEMIANNUALLY]: 6,
    [RateBillingFrequencyEnum.ANNUALLY]: 12,
    // NOTE: purposefully set to 1 so that I don't have to deal with them and they all are being ignore
    [RateBillingFrequencyEnum.DAILY]: 1,
    [RateBillingFrequencyEnum.CUSTOM]: 1,
    [RateBillingFrequencyEnum.ONETIME]: 1,
  };

  return displayUnitPriceFrequency ?? defaultFrequencyMap[billingFrequency];
};

export const getQuotePricesForQuoteItem = ({
  quoteOfferingId,
  quotePrices,
  productId,
  rateId,
  parentQuoteOfferingId,
}: {
  quoteOfferingId: string;
  quotePrices: IQuotePrice[];
  productId: string;
  rateId: string;
  parentQuoteOfferingId?: string;
}) => {
  return (
    quotePrices
      ?.filter(
        (price) =>
          price.quoteOfferingId === quoteOfferingId ||
          price.quoteOfferingId === parentQuoteOfferingId,
      )
      .flatMap((item) => item.schedule)
      .find(
        (schedule) =>
          schedule.rate.id === rateId &&
          schedule.quoteOfferingId === quoteOfferingId,
      )
      ?.productPrices.filter(
        (productPrice) => productPrice.product.id === productId,
      )?.[0]?.prices || []
  );
};

export const buildContractOptions = (
  contracts: IContract[],
  quotes: IQuoteRespSchema[],
): IContractWithQuotes[] => {
  const validContracts = contracts.filter(({ renewed }) => !renewed);

  const latestQuoteByContractId = groupBy(
    quotes.filter(
      ({ type, status: quoteStatus }) =>
        (type === QuoteTypeEnum.NEW || type === QuoteTypeEnum.RENEWAL) &&
        quoteStatus === QuoteStatusEnum.PROCESSED,
    ),
    'contractId',
  );

  const contractWithQuotes = validContracts.map((contract) => ({
    ...contract,
    quotes:
      latestQuoteByContractId[contract.id] &&
      latestQuoteByContractId[contract.id].length
        ? latestQuoteByContractId[contract.id]
        : [],
  }));

  return contractWithQuotes;
};

export const isContractLengthWholeYear = (contractLength: number): boolean => {
  return contractLength % 12 === 0;
};

export const getContractLengthInMonthOrYear = (
  isCustomContractLength: boolean,
  contractLength: number,
): number => {
  if (!isCustomContractLength && contractLength % 12 === 0) {
    return contractLength / 12; //As api return contractLength value in months so converting to Year
  }

  return contractLength;
};

export const getPriceDisplayWithFrequency = ({
  price,
  productType,
  priceFrequency,
}: {
  price: string;
  productType: ProductTypeEnum;
  priceFrequency?: number;
}): string => {
  if (ONE_TIME_PRODUCT_TYPES.has(productType)) {
    return `${price}`;
  }

  if (productType === ProductTypeEnum.USAGE) {
    return `${price}/unit`;
  }

  return `${price}/${getShortCodeForUnitPriceFrequency(priceFrequency)}`;
};
