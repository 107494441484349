import { useMemo } from 'react';
import { useGetById } from '../../../api/queryUtils';
import {
  DynamicLabelValueBanner,
  LabelValueLinkItem,
} from '../../../components/DynamicLabelValueBanner/DynamicLabelValueBanner';
import { MBox, MSpinner } from '../../../components/Monetize';
import {
  getBillGroupPageRoute,
  getInvoiceDetailRoute,
} from '../../../constants/routes';
import { useCustomFields } from '../../../hooks/useCustomFields';
import {
  CustomFieldEntityEnum,
  IBillGroupResp,
  ICustomFieldRecordSchema,
  IInvoiceRespSchema,
  Maybe,
} from '../../../types';
import { handleCustomFieldToDisplayBannerFormat } from '../../../utils/customFields';

interface CreditAdditionalFieldsBannerProps {
  invoice?: Maybe<Pick<IInvoiceRespSchema, 'invoiceNumber' | 'id'>>;
  billGroupId?: string;
  isLoading: boolean;
  customFields: ICustomFieldRecordSchema;
  reason: string;
  isEditable?: boolean;
  additionalValues?: LabelValueLinkItem[];
  onOpen: () => void;
  voidReason: string;
}

/**
 * This banner works for Credits and Credit Notes
 */
export const CreditAdditionalFieldsBanner = ({
  invoice,
  billGroupId,
  customFields,
  isLoading,
  reason,
  isEditable = true,
  additionalValues,
  voidReason = '',
  onOpen,
}: CreditAdditionalFieldsBannerProps) => {
  const { data: billGroup } = useGetById<IBillGroupResp>(
    'billGroups',
    billGroupId!,
    {
      enabled: !!billGroupId,
    },
  );

  const { customFieldList, isLoading: isCustomFieldLoading } = useCustomFields(
    CustomFieldEntityEnum.CREDIT_NOTE,
  );

  const items = useMemo(() => {
    const values = additionalValues || [];
    if (invoice) {
      values.push({
        label: 'Created From Invoice',
        value: invoice.invoiceNumber ?? invoice.id,
        link: getInvoiceDetailRoute(invoice.id),
      });
    }
    if (billGroup) {
      values.push({
        label: 'Bill Group',
        value: billGroup.name,
        link: getBillGroupPageRoute(billGroup.id),
      });
    }
    if (reason) {
      values.push({
        label: 'Credit Reason',
        value: reason,
      });
    }
    if (voidReason) {
      values.push({
        label: 'Void Reason',
        value: voidReason,
      });
    }
    return [
      ...values,
      ...handleCustomFieldToDisplayBannerFormat(customFields, customFieldList),
    ];
  }, [
    additionalValues,
    invoice,
    billGroup,
    customFieldList,
    customFields,
    reason,
    voidReason,
  ]);

  if (isCustomFieldLoading && isLoading) {
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }

  return (
    <DynamicLabelValueBanner
      title="Additional Fields"
      items={items}
      isEditable={isEditable}
      onEditClick={onOpen}
    />
  );
};
