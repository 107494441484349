import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MHStack,
  MPageHeader,
  MPageSearchInput,
  shouldOpenInNewWindow,
} from '../../../components/Monetize';
import { MDataTableFilter } from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import {
  getAccountQuoteNewRoute,
  getBillGroupPageRoute,
} from '../../../constants/routes';
import { useACL } from '../../../services/acl/acl';
import { ACLCheck } from '../../../services/acl/ACLCheck';
import {
  FilterTypeOperator,
  IAccountRespSchema,
  IBillGroupResp,
  IQuoteRespSchema,
} from '../../../types';
import { getFiltersApplied, getIsTrulyEmptyList } from '../../../utils';
import {
  statusBodyTemplate,
  totalBodyTemplate,
  typeBodyTemplate,
} from '../../../utils/quotes';
import {
  dateTimeBodyTemplate,
  headerAndIdTemplate,
  nameWithIdBodyTemplate,
} from '../../../utils/tableUtils';
import {
  IQuoteWithBillGroup,
  useQuotesTableData,
} from '../../Quotes/Quote/QuoteListMain';

const QuoteListAccount = ({
  account,
  billGroupId,
}: {
  account: IAccountRespSchema;
  billGroupId?: string;
}) => {
  const { id: accountId = '' } = account;
  const {
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    quoteList,
    pager,
    setPager,
    loading,
    actionBodyTemplate,
    handleReviewQuote,
    handleEditQuote,
    filterOptions,
  } = useQuotesTableData({ accountId, billGroupId });
  const { canDo } = useACL();

  const handleOnRowClick = (e: DataTableRowClickEvent) => {
    if (e.data?.status === 'DRAFT') {
      handleEditQuote(e.data?.id, shouldOpenInNewWindow(e));
    } else {
      handleReviewQuote(e.data?.id, shouldOpenInNewWindow(e));
    }
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'id',
      header: 'Name',
      body: headerAndIdTemplate<IQuoteRespSchema>('description', 'id'),
    },
    {
      className: 'overflow-hidden',
      field: 'billGroupId',
      header: 'Bill Group',
      body: nameWithIdBodyTemplate<IQuoteWithBillGroup, IBillGroupResp>(
        'billGroup',
        { idLinkFn: (id) => getBillGroupPageRoute(id) },
      ),
      style: {
        width: '12.5rem',
      },
    },
    {
      field: 'modifyDate',
      header: 'Updated',
      body: dateTimeBodyTemplate<IQuoteWithBillGroup>('modifyDate'),
      sortable: true,
    },
    {
      field: 'type',
      header: 'Type',
      body: typeBodyTemplate,
      sortable: true,
    },
    { field: 'status', header: 'Status', body: statusBodyTemplate },
    {
      field: 'amount',
      header: 'Total',
      body: totalBodyTemplate,
      style: { textAlign: 'right' },
      sortable: true,
    },
    { field: 'action', header: '', sortable: false, body: actionBodyTemplate },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: quoteList?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MBox>
      <MPageHeader title="Quotes" hideContent={isTrulyEmptyList}>
        <MFlex>
          <MHStack spacing="2">
            <MPageSearchInput
              placeholderKey="Quote Name"
              value={searchTerm || ''}
              onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
              count={quoteList?.totalElements}
              resetSearch={searchComponentReset}
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="quotes"
              filters={filters}
              internalFilters={[
                {
                  key: 'accountId',
                  value: accountId,
                  operator: FilterTypeOperator.EQUAL,
                },
              ]}
              applyInternalFiltersWithoutConfirmation
              getFilename={() => `quotes-${accountId}.csv`}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
            />
            <ACLCheck acls={[['sales', 'create']]}>
              <MButton
                variant="secondary"
                as={Link}
                to={getAccountQuoteNewRoute(accountId)}
              >
                New Quote
              </MButton>
            </ACLCheck>
          </MHStack>
        </MFlex>
      </MPageHeader>

      <MDataTable
        value={quoteList?.content}
        totalRecords={quoteList?.totalElements}
        totalPages={quoteList?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no quotes here.',
          btnLabel: canDo([['sales', 'create']]) ? 'New Quote' : '',
          to: getAccountQuoteNewRoute(accountId),
        }}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        loading={loading}
        columns={columns}
        onRowClick={handleOnRowClick}
      />
    </MBox>
  );
};

export default QuoteListAccount;
