import { Divider, Heading, List, ListItem } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  isValidDate,
  toDateShort,
  toDateTimeShort,
} from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { endOfDay } from 'date-fns/endOfDay';
import { parseISO } from 'date-fns/parseISO';
import isDate from 'lodash/isDate';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../api/axios';
import {
  useCreateQuoteRecordShareAccess,
  useGetQuoteShareAccess,
  useRevokeQuoteRecordShare,
} from '../../../api/cpqService';
import { useGetRecordShareEngagements } from '../../../api/shareService';
import {
  CAN_SHARE_QUOTE_EXTERNALLY_STATUSES,
  SHARE_QUOTE_EXTERNALLY_DRAFT_STATUSES,
} from '../../../constants/quotes';
import { useNavigatorPermission } from '../../../hooks/useNavigatorPermission';
import { useFlags } from '../../../services/launchDarkly';
import { logger } from '../../../services/logger';
import {
  COLLABORATION_ACCESS_DISPLAY,
  CollaborationAccessLevelEnum,
  QuoteShareAccessRequest,
  QuoteShareAccessRequestSchema,
  QuoteStatusEnum,
} from '../../../types';
import { RecordShareEngagementActionEnglishDisplay } from '../../../types/recordShareTypes';
import { objectToSelectOptions } from '../../../utils/misc';
import { MBox, MButton, MFlex, MFormField, MText } from '../../Monetize';
import { DatePicker } from '../../Monetize/DatePicker/DatePicker';
import { QuoteCollaborationCopyLink } from './QuoteCollaborationCopyLink';
import { QuoteCollaborationRevokeLink } from './QuoteCollaborationRevokeLink';

interface QuoteCollaborationExternalProps {
  quoteId: string;
  quoteExpirationDate: string;
  quoteStatus: QuoteStatusEnum;
  accessLevel: CollaborationAccessLevelEnum | null;
}

export const internalCollaborationOptions = objectToSelectOptions(
  COLLABORATION_ACCESS_DISPLAY,
);

export const QuoteCollaborationExternal = ({
  quoteId,
  quoteExpirationDate,
  accessLevel,
  quoteStatus,
}: QuoteCollaborationExternalProps) => {
  const { canCopyClipboard } = useNavigatorPermission();
  const { quoteShareConfig } = useFlags();

  const minExpiresAt = useMemo(() => endOfDay(addDays(new Date(), 1)), []);
  const maxExpiresAt = useMemo(() => {
    const date = parseISO(quoteExpirationDate);
    if (isValidDate(date)) {
      return endOfDay(date);
    }
    return endOfDay(addDays(new Date(), 30));
  }, [quoteExpirationDate]);

  const { data: recordShareAccess } = useGetQuoteShareAccess(quoteId, {
    enabled:
      quoteShareConfig?.enabled &&
      accessLevel === CollaborationAccessLevelEnum.WRITE,
  });

  const { data: engagements } = useGetRecordShareEngagements(quoteId);

  const {
    mutateAsync: addRecordShareAccess,
    isPending: isSaveRecordAccessLoading,
  } = useCreateQuoteRecordShareAccess();

  const {
    mutateAsync: revokeRecordShareAccess,
    isPending: isRevokeRecordAccessLoading,
  } = useRevokeQuoteRecordShare();

  const {
    control: recordShareControl,
    handleSubmit: handleSubmitRecordShare,
    formState: { errors: recordShareErrors, isValid: isRecordShareValid },
  } = useForm<QuoteShareAccessRequest>({
    resolver: zodResolver(QuoteShareAccessRequestSchema),
    defaultValues: {
      quoteId,
      expiresAt: quoteExpirationDate,
    },
  });

  // TODO: validate this is accurate
  const hasWriteAccess = accessLevel === CollaborationAccessLevelEnum.WRITE;

  async function handleExternalShare(data: QuoteShareAccessRequest) {
    try {
      await addRecordShareAccess({
        ...data,
        expiresAt: endOfDay(
          isDate(data.expiresAt) ? data.expiresAt : parseISO(data.expiresAt),
        ),
      });
    } catch (ex) {
      handleApiErrorToast(ex);
      logger.warn('Error sharing quote', ex);
    }
  }

  // This looks complicated - but basically if quote is processed or cancelled only show this section if the have share access
  const externalShareInTerminalStateWithoutShareRecords =
    !new Set([
      ...CAN_SHARE_QUOTE_EXTERNALLY_STATUSES,
      ...SHARE_QUOTE_EXTERNALLY_DRAFT_STATUSES,
    ]).has(quoteStatus) &&
    !recordShareAccess?.length &&
    !engagements?.length;

  const externalShareState = {
    externalShareVisible:
      !!quoteShareConfig?.enabled &&
      !externalShareInTerminalStateWithoutShareRecords,
    canCreateShareLink:
      hasWriteAccess &&
      CAN_SHARE_QUOTE_EXTERNALLY_STATUSES.has(quoteStatus) &&
      !recordShareAccess?.length, // In future we will allow multiple external shares (backend allows it)
    showDraftMessage:
      hasWriteAccess && SHARE_QUOTE_EXTERNALLY_DRAFT_STATUSES.has(quoteStatus),
    hasRecordShareRecords: !!recordShareAccess?.length,
    showExternalActivity: !!engagements?.length || !!recordShareAccess?.length,
  };

  if (!externalShareState.externalShareVisible) {
    return null;
  }

  return (
    <>
      <Divider my={6} />
      <Heading as="h4" fontSize="sm" mb={2}>
        Externally
      </Heading>
      <MText mb={2}>
        Allows people outside of your organization to view this Quote.
      </MText>

      {externalShareState.showDraftMessage && (
        <MText mb={2} fontWeight="600">
          This option will be available once your quote is approved.
        </MText>
      )}

      <>
        {externalShareState.canCreateShareLink && (
          <form
            onSubmit={handleSubmitRecordShare(handleExternalShare, (err) =>
              logger.warn(err),
            )}
          >
            <MFlex alignItems="end" justifyContent="space-between" mt={2}>
              <MBox>
                <MFormField
                  label="Expiration Date"
                  error={recordShareErrors?.expiresAt}
                >
                  <Controller
                    name="expiresAt"
                    control={recordShareControl}
                    render={({ field }) => (
                      <DatePicker
                        minDate={minExpiresAt}
                        maxDate={maxExpiresAt}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MBox>
              <MBox>
                <MButton
                  variant="primary"
                  isLoading={isSaveRecordAccessLoading}
                  isDisabled={isSaveRecordAccessLoading || !isRecordShareValid}
                  type="submit"
                >
                  Share
                </MButton>
              </MBox>
            </MFlex>
          </form>
        )}

        {externalShareState.hasRecordShareRecords && (
          <>
            <Heading as="h4" fontSize="sm" mt={4} mb={2}>
              Access
            </Heading>
            <MBox maxH="25vh" overflowY="auto">
              {recordShareAccess?.map(({ id, expiresAt, url }) => (
                <MBox key={id} mb={1}>
                  <MFlex alignItems="center" justifyContent="space-between">
                    <MFlex flexDir="column">
                      <MText>Expires: {toDateShort(expiresAt)}</MText>
                    </MFlex>
                    <MFlex>
                      <QuoteCollaborationRevokeLink
                        isLoading={isRevokeRecordAccessLoading}
                        onRevoke={() =>
                          revokeRecordShareAccess({
                            recordShareId: id,
                            quoteId,
                          })
                        }
                        mr={2}
                      />
                      <QuoteCollaborationCopyLink
                        canCopyClipboard={canCopyClipboard}
                        link={url}
                      />
                    </MFlex>
                  </MFlex>
                </MBox>
              ))}
            </MBox>
          </>
        )}

        {externalShareState.showExternalActivity && (
          <>
            <Heading as="h4" fontSize="sm" mt={4} mb={2}>
              External Activity
            </Heading>
            {engagements && engagements?.length > 0 ? (
              <List spacing={1} overflowY="auto" maxH="25vh">
                {engagements.map((item) => (
                  <ListItem key={item.id}>
                    <MFlex justifyContent="space-between">
                      <MText>
                        {RecordShareEngagementActionEnglishDisplay[item.action]}
                      </MText>
                      <MText>{toDateTimeShort(item.timestamp)}</MText>
                    </MFlex>
                  </ListItem>
                ))}
              </List>
            ) : (
              <MText fontStyle="italic">
                This link has not yet been viewed
              </MText>
            )}
          </>
        )}
      </>
    </>
  );
};
