import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import isString from 'lodash/isString';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { ENVIRONMENT } from '../config';
import { ROUTES } from '../constants';
import { logger } from '../services/logger';
import Sentry from '../services/sentry';
import { AddToastItem } from '../services/toast';
import {
  accessTokenState,
  activeToastState,
  currentTenantState,
  currentUserIdState,
  navigationState,
  refreshAccessTokenState,
  tempRedirectInfoState,
} from '../store/global.store';
import {
  ApiListInfiniteScrollResponse,
  ApiListResponse,
  GetListApiConfig,
  Maybe,
  SortOrder,
} from '../types';
import { composeGetQuery } from './utils';

/**
 * Only logout for 401s on routes that start with these prefixes
 * Connector services or other 3rd party api requests may return 401s and that does not mean it is an authentication failure for MN
 */
const LOGOUT_ON_401_ROUTE_PREFIXES = ['/api/'];
const LOCAL_RULES_PATH_PREFIX = '/rules-v3';

const axiosInstance = axios.create({
  baseURL: ENVIRONMENT.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Attempt to auto-refresh the access token if we get a 401
 * If a user focuses on the page, react-query will make requests
 * before a refresh attempt is able to be made. This handles the refresh
 * just-in-time and ensures the updated access token if saved to the store.
 *
 * {@link https://github.com/Flyrell/axios-auth-refresh}
 */
const refreshAuthLogic = async (failedRequest: any) => {
  try {
    const refreshAccessToken = getRecoil(refreshAccessTokenState);
    if (!refreshAccessToken) {
      throw failedRequest;
    }

    logger.log(
      `[HTTP][REQ][UNAUTHORIZED] Attempting auto-refresh`,
      failedRequest.response.config.url,
    );

    const accessToken = await refreshAccessToken();
    // request will be retired with this header
    failedRequest.response.config.headers.authorization = `Bearer ${accessToken}`;
    // Update store so all other paused request have the up-to-date data
    setRecoil(accessTokenState, accessToken);
  } catch (ex) {
    throw failedRequest;
  }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (confA: InternalAxiosRequestConfig) => {
    const config = confA as MAxiosRequestConfig;

    // If running rules locally, remove baseURL - proxy configuration will handle it
    if (
      ENVIRONMENT.LOCAL_RULES &&
      config.url?.startsWith(LOCAL_RULES_PATH_PREFIX)
    ) {
      config.baseURL = undefined;
    }

    logger.log(`[HTTP][REQ][${config?.method?.toUpperCase()}]`, config.url, {
      request: config,
    });

    const userId = getRecoil(currentUserIdState);
    const currentTenant = getRecoil(currentTenantState);
    const accessToken = getRecoil(accessTokenState);

    config.headers = config.headers || ({} as AxiosRequestHeaders);

    if (config.method !== 'get' && !config.data) {
      config.data = config.data ?? undefined;
    }

    if (!config.axiosConfig?.excludeTokenIdFromHeader) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }

    if (!config.axiosConfig?.excludeTenantIdFromHeader && currentTenant?.id) {
      config.headers['x-tenant-id'] = currentTenant.id;
    }

    if (!config.axiosConfig?.excludeUserIdFromHeader && userId) {
      config.headers['x-user-id'] = userId;
    }

    if (config.axiosConfig?.customXTenantId) {
      config.headers['x-tenant-id'] = config.axiosConfig.customXTenantId;
    }

    return config as InternalAxiosRequestConfig;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => {
    logger.log(
      `[HTTP][RES][${response.config?.method?.toUpperCase()}]`,
      response.config.url,
      { response: response.data },
    );
    return response;
  },
  (error: AxiosError) => {
    const currentTenant = getRecoil(currentTenantState);
    const url = error.response?.config?.url;
    const statusCode = error?.response?.status || 400;
    logger.warn(
      '[HTTP][RES][ERROR]',
      error.name,
      error.message,
      error.response?.data,
    );

    if (
      statusCode === 401 &&
      LOGOUT_ON_401_ROUTE_PREFIXES.some((prefix) => url?.startsWith(prefix))
    ) {
      setRecoil(tempRedirectInfoState, {
        tenantId: currentTenant?.id,
        redirectUrl: `${window.location.pathname}${window.location.search}`,
      });
      setRecoil(navigationState, { route: ROUTES.AUTH_LOGOUT });
    }

    // Log errors to Sentry
    if ([500].includes(statusCode) && !axios.isCancel(error)) {
      Sentry.captureException(error, {
        tags: {
          type: 'API_CALL',
          config: (error as any)?.config as any,
          statusCode,
        },
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;

export interface MAxiosCustomConfig {
  excludeTenantIdFromHeader?: boolean;
  excludeUserIdFromHeader?: boolean;
  excludeTokenIdFromHeader?: boolean;
  customXTenantId?: string;
}

export interface MAxiosRequestConfig
  extends Partial<InternalAxiosRequestConfig> {
  axiosConfig?: MAxiosCustomConfig;
}

export async function apiGet<T = any>(
  resource: string,
  config?: MAxiosRequestConfig,
) {
  return axiosInstance.get<T>(resource, config);
}

function calculateProgress({ totalPages, pageable }: ApiListResponse) {
  if (!pageable) {
    return 0;
  }
  const currentPage = pageable.pageNumber + 1; // Convert 0-based index to 1-based
  const progress = (currentPage / totalPages) * 100;
  return progress;
}

/**
 * Auto-paginate all results for APIs
 */
export async function apiGetAllList<T = unknown>(
  resource: string,
  {
    rows = 100,
    sortField,
    sortOrder,
    filters,
    config,
    onProgress,
  }: {
    rows?: number;
    filters?: any;
    sortField?: string;
    sortOrder?: SortOrder;
    config?: GetListApiConfig | MAxiosRequestConfig;
    onProgress?: (progress: number) => void;
  } = {},
): Promise<T[]> {
  let done = false;
  let results: T[] = [];
  let page = 0;
  // ensure sort information included on config is not overwritten
  sortField = sortField || (config as GetListApiConfig)?.sortField;
  sortOrder = sortOrder || (config as GetListApiConfig)?.sortOrder;
  onProgress && onProgress(0);
  while (!done) {
    const params = composeGetQuery(
      { ...config, page, rows, sortField, sortOrder },
      filters,
    );
    const { data } = await axiosInstance.get<ApiListResponse<T>>(resource, {
      params,
    });
    results = [...results, ...data.content];
    done = page + 1 >= data.totalPages;
    page++;
    onProgress && onProgress(calculateProgress(data));
  }
  return results;
}

/**
 * Auto-paginate for APIs that use nextPageToken to fetch next set of records
 */
export async function apiGetAllListInfiniteScroll<T = unknown>(
  resource: string,
  {
    rows = 100,
    sortField,
    sortOrder,
    filters,
    config,
  }: {
    rows?: number;
    filters?: any;
    sortField?: string;
    sortOrder?: SortOrder;
    config?: MAxiosRequestConfig;
  } = {},
): Promise<T[]> {
  let done = false;
  let results: T[] = [];
  let nextPageToken: Maybe<string> = undefined;
  while (!done) {
    const params = composeGetQuery(
      { ...config, nextPageToken, rows, sortField, sortOrder },
      filters,
    );
    const { data } = await axiosInstance.get<ApiListInfiniteScrollResponse<T>>(
      resource,
      {
        params,
      },
    );
    results = [...results, ...data.content];
    done = !data.pageable?.nextPageToken;
    nextPageToken = data.pageable?.nextPageToken;
  }
  return results;
}

export async function apiPost<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig,
) {
  return axiosInstance.post<T>(resource, data, {
    ...config,
    headers: {
      ...config?.headers,
    },
  });
}

export async function apiDelete<T = any>(
  resource: string,
  config?: MAxiosRequestConfig,
) {
  return axiosInstance.delete<T>(resource, config);
}

export async function apiPatch<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig,
) {
  return axiosInstance.patch<T>(resource, data, config);
}

export async function apiPut<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig,
) {
  return axiosInstance.put<T>(resource, data, config);
}

export async function apiUpload<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig,
  progressCallback?: (progress: number) => void,
) {
  return axiosInstance.post<T>(resource, data, {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const uploadPercentage = parseInt(
        String(
          Math.round(
            ((progressEvent.loaded / (progressEvent.total ?? 1)) *
              100) as number,
          ),
        ),
        10,
      );

      progressCallback && progressCallback(uploadPercentage);
    },
  });
}

export async function apiUploadPut<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig,
  progressCallback?: (progress: number) => void,
) {
  return axiosInstance.put<T>(resource, data, {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const uploadPercentage = parseInt(
        String(
          Math.round(
            ((progressEvent.loaded / (progressEvent.total ?? 1)) *
              100) as number,
          ),
        ),
        10,
      );

      progressCallback && progressCallback(uploadPercentage);
    },
  });
}

export const handleApiErrorToast = (err: any, defaultValues?: AddToastItem) => {
  try {
    logger.info('[API ERROR]', { err });
    if (axios.isCancel(err) || err.name === 'AbortError') {
      // xhr's Promise was cancelled, probably by our code with AbortController.abort()
      return;
    }

    const { summary, detail } = parseMessageFromError(err, defaultValues);

    if (summary) {
      setRecoil(activeToastState, {
        life: 5000,
        severity: 'error',
        summary,
        detail,
      });
    }
  } catch (error) {
    logger.warn('[handleApiErrorToast]', error);
  }
};

export const parseMessageFromError = (
  err: any,
  defaultValues?: AddToastItem,
) => {
  let summary = defaultValues?.summary || '';
  let detail = defaultValues?.detail || '';
  const errorData = err?.response?.data;

  if (errorData && errorData.apierrorresponse) {
    const errorResp = errorData.apierrorresponse;
    if (errorResp.status === 'BAD_REQUEST') {
      summary = errorResp.message;

      if (errorResp.subErrors?.length > 0) {
        // detail = `${errorResp.subErrors[0].field} ${errorResp.subErrors[0].message}`;
        detail = `${errorResp.subErrors[0].message}`;
      }
    } else {
      // BREN-TODO need more implementation of error handlers
    }
  } else if (errorData && isString(errorData.message) && errorData.message) {
    // general handlers without correct error api response
    summary = err.name || 'Error';
    detail = errorData.message;
  } else if (errorData && isString(errorData.details) && errorData.details) {
    summary = err.name || 'Error';
    detail = errorData.details;
  } else if (err?.response?.data?.failedEvents) {
    // usage error handler
    summary = err.response.data?.status;
    detail = err.response.data?.failedEvents[0].reason;
  } else if (
    err instanceof Error &&
    !err.message?.startsWith('Request failed with status code')
  ) {
    summary = 'Error';
    detail = err.message;
  } else {
    // general handlers without correct error api response
    summary = err.name || 'Error';
    detail = !err.message?.startsWith('Request failed with status code')
      ? err.message
      : 'Something went wrong. Please try again later.';
  }
  if (summary === 'AxiosError') {
    summary = 'Error';
  }
  return { summary, detail };
};

export function isAxiosError(error: any): error is AxiosError {
  return !!error?.response;
}

export function getMessageFromError(
  error: unknown,
  fallback = 'Something went wrong. Please try again later.',
): string {
  return isAxiosError(error) && isString((error.response?.data as any)?.details)
    ? (error.response?.data as any)?.details || fallback
    : fallback;
}

/**
 * Checks if the given error is an Axios error with any of the specified status codes.
 *
 * @param {any} error - The error object to check.
 * @param {...number[]} statusCodes - The status codes to check against.
 * @returns {boolean} True if the error is an Axios error with any of the specified status codes, false otherwise.
 */
export const isAxiosErrorMatchedStatus = (
  error: any,
  ...statusCodes: number[]
) => {
  return (
    isAxiosError(error) && statusCodes.includes(error.response?.status || 0)
  );
};
