import { Box, Flex, Icon, Text, TextProps, Tooltip } from '@chakra-ui/react';
import React, { FunctionComponent as FC, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { ENVIRONMENT } from '../../config';
import { useNavigatorPermission } from '../../hooks/useNavigatorPermission';
import { copyToClipboard } from '../../utils';
import { MTooltip } from './MTooltip';

interface MClipboardCopyBoxProps extends TextProps {
  copyValue: string;
  displayText?: string;
  fontSize?: string;
  isDisabled?: boolean;
  tooltipMessage?: string;
  renderContent?: null | (() => React.ReactElement);
  onCopyContent?: ({
    copyToClipboard,
    setCopiedContent,
    setShowCopyTooltip,
  }: {
    copyToClipboard: (str: string) => void;
    setCopiedContent: (val: boolean) => void;
    setShowCopyTooltip: (val: boolean) => void;
  }) => void;
}

export const MClipboardCopyBox: FC<MClipboardCopyBoxProps> = React.forwardRef<
  any,
  MClipboardCopyBoxProps
>(
  (
    {
      copyValue,
      displayText,
      fontSize = 'sm',
      isDisabled: isDisabledProp,
      tooltipMessage = 'Copy to Clipboard',
      renderContent,
      onCopyContent,
      ...rest
    }: MClipboardCopyBoxProps,
    ref,
  ) => {
    const [copiedContent, setCopiedContent] = useState<boolean>(false);
    const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);
    const { canCopyClipboard } = useNavigatorPermission();
    const isDisabled = isDisabledProp || !canCopyClipboard;

    const copyContent = (ev: React.MouseEvent<HTMLElement>) => {
      if (isDisabled) {
        return;
      }
      if (onCopyContent) {
        onCopyContent({
          copyToClipboard,
          setCopiedContent,
          setShowCopyTooltip,
        });
      } else {
        ev.stopPropagation();
        copyToClipboard(copyValue);
        setCopiedContent(true);
        setShowCopyTooltip(false);
        setTimeout(() => {
          setCopiedContent(false);
        }, 1000);
      }
    };

    const copiedLabel = !isDisabled && (
      <Flex alignItems="center">
        <Icon as={MdCheckCircle} w="4" h="4" mr="1" />
        <Text>Copied!</Text>
      </Flex>
    );

    // there is a bug with Chakra handling background colors of this tooltip that occurs only in test, so
    // this temporary workaround skips the custom color
    const bgColor =
      ENVIRONMENT.NODE_ENV === 'test' ? {} : { bg: 'tPurple.dark' };

    return (
      <Flex alignItems="center">
        <MTooltip
          hasArrow
          shouldWrapChildren
          label={isDisabled ? undefined : tooltipMessage}
          isOpen={showCopyTooltip}
          placement="top-start"
          {...bgColor}
        >
          <Box
            onMouseEnter={() => !isDisabled && setShowCopyTooltip(true)}
            onMouseLeave={() => !isDisabled && setShowCopyTooltip(false)}
          >
            <Tooltip
              hasArrow
              label={copiedLabel}
              placement="top-start"
              isOpen={copiedContent}
              closeDelay={1000}
              {...bgColor}
            >
              {!renderContent ? (
                <Text
                  fontSize={fontSize}
                  fontWeight="normal"
                  color="tGray.darkGrayPurple"
                  onClick={copyContent}
                  cursor={!isDisabled ? 'pointer' : 'default'}
                  {...rest}
                >
                  {displayText && (
                    <Text as="span" fontWeight="bold" mr={1}>
                      {displayText}
                    </Text>
                  )}
                </Text>
              ) : (
                <Box
                  onClick={copyContent}
                  as="span"
                  display="flex"
                  alignItems="center"
                >
                  {renderContent()}
                </Box>
              )}
            </Tooltip>
          </Box>
        </MTooltip>
      </Flex>
    );
  },
);
