import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { doGetReportingJWT } from '../../api/reportingService';
import { METABASE_CONFIG } from '../../config';
import { useToast } from '../../services/toast';
import { ReportJWTResp } from '../../types/repotingTypes';
import { MBox, MSpinner } from '../Monetize';

export const EmbeddedMetabase = () => {
  const params = new URLSearchParams(METABASE_CONFIG.appearance);
  const [metabaseJWT, setMetabaseJWT] = useState<ReportJWTResp | null>(null);
  const { current: metabaseSearchParams } = useRef(
    new URLSearchParams(METABASE_CONFIG.appearance),
  );
  const { addToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const jwtResp = await doGetReportingJWT();
        metabaseSearchParams.append('jwt', jwtResp.jwt);
        setMetabaseJWT(jwtResp);
      } catch (err) {
        addToast({
          summary: 'Error',
          detail: 'Failed to fetch credentials. Please try again later.',
          severity: 'error',
        });
        navigate(-1);
      }
    })();
  }, []);

  if (!metabaseJWT || !metabaseJWT.jwt) {
    return (
      <MBox
        height="50vh"
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <MSpinner />
      </MBox>
    );
  }

  return (
    <MBox height="100%" flex="1" display="flex" flexDirection="column">
      <iframe
        src={`${
          METABASE_CONFIG.embeddedUrl
        }?${metabaseSearchParams.toString()}`}
        style={{ width: '100%', height: '100%', flex: 1 }}
      />
    </MBox>
  );
};
