import { Fragment } from 'react';
import {
  MBox,
  MFlex,
  MSkeleton,
  MText,
} from '../../../../../components/Monetize';
import { MPercent } from '../../../../../components/Monetize/MPercent';
import MRowWithLineSeparator from '../../../../../components/Monetize/MRowWithLineSeparator';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '../../../../../constants/products';
import {
  IOfferingSummaryResSchema,
  IQuotePrice,
  IQuotePriceScheduleProductPrice,
  OfferingTypesEnum,
  ProductTypeEnum,
  TQuotePrice,
} from '../../../../../types';
import { formatCurrency } from '../../../../../utils';
import { getPriceDisplayWithFrequency } from '../../../../../utils/quotes';

const LoadingSkeletons = () => (
  <MBox mt={2}>
    <MSkeleton height="5" width="30%" mb="2" />
    <MSkeleton height="3" width="20%" mb="2" />
    <MSkeleton height="3" width="100%" mb="2" />
  </MBox>
);

const ProductPrices = ({
  prices,
  productType,
  priceFrequency,
  isPoTOfferingType,
  currency,
}: {
  prices: TQuotePrice[];
  productType: ProductTypeEnum;
  priceFrequency?: number;
  isPoTOfferingType: boolean;
  currency: string;
}) => (
  <>
    {prices.map((productPrice, productPriceIndex) => (
      <MFlex
        flexDir="row"
        align="center"
        justify="space-between"
        key={productPriceIndex}
      >
        <MRowWithLineSeparator
          my={0.5}
          lineProps={{ background: 'tBlue.lightShade' }}
          leftLabel={
            <MText>
              {productPrice.from} - {productPrice.to || '∞'}
            </MText>
          }
          rightLabel={
            <MFlex>
              {isPoTOfferingType ? (
                <>
                  <MPercent amount={productPrice.net} />
                  <MText ml="1" as="span">
                    of other products
                  </MText>
                </>
              ) : (
                <MText>
                  {getPriceDisplayWithFrequency({
                    price: formatCurrency(productPrice.displayNet, {
                      currency,
                      maximumFractionDigits: 2,
                    }),
                    productType: productType,
                    priceFrequency: priceFrequency,
                  })}
                </MText>
              )}
            </MFlex>
          }
        />
      </MFlex>
    ))}
  </>
);

const ProductPriceSection = ({
  productPrices,
  isPoTOfferingType,
  currency,
}: {
  productPrices: IQuotePriceScheduleProductPrice[];
  isPoTOfferingType: boolean;
  currency: string;
}) => {
  return (
    <>
      {productPrices.map((price, priceIndex) => (
        <MBox key={priceIndex}>
          <MFlex flexDir="row" align="center" justify="space-between">
            <MRowWithLineSeparator
              my={0.5}
              lineProps={{ background: 'transparent' }}
              leftLabel={
                <MFlex width="100%">
                  <MText fontWeight="bold" fontStyle="italic">
                    {price.product.name}&nbsp;
                  </MText>
                  <MText fontWeight="medium" fontStyle="italic">
                    ({PRODUCT_TYPE_DISPLAY_SHORT[price.product.productType]})
                  </MText>
                </MFlex>
              }
              rightLabel={null}
            />
          </MFlex>
          <ProductPrices
            prices={price.prices}
            productType={price.product.productType}
            priceFrequency={price.displayUnitPriceFrequency}
            isPoTOfferingType={isPoTOfferingType}
            currency={currency}
          />
        </MBox>
      ))}
    </>
  );
};

const QuotePriceSchedules = ({
  quotePrice,
  isPoTOfferingType,
  currency,
}: {
  quotePrice: IQuotePrice;
  isPoTOfferingType: boolean;
  currency: string;
}) => (
  <>
    {quotePrice.schedule.map((schedule) => (
      <Fragment key={schedule.quoteOfferingId}>
        <MFlex>
          <MText fontWeight="bold" fontSize="md" my="2">
            {quotePrice.offering.name}{' '}
            {schedule.rate?.name ? `- ${schedule.rate.name}` : ''}
          </MText>
        </MFlex>
        <ProductPriceSection
          productPrices={schedule.productPrices}
          isPoTOfferingType={isPoTOfferingType}
          currency={currency}
        />
      </Fragment>
    ))}
  </>
);

const PricesSectionContent = ({
  quotePrices,
  loading,
  currency,
  offeringsById = {},
}: {
  quotePrices: IQuotePrice[];
  loading: boolean;
  currency: string;
  offeringsById?: Record<string, IOfferingSummaryResSchema>;
}) => {
  if (loading) {
    return <LoadingSkeletons />;
  }

  return (
    <>
      {quotePrices.map((quotePrice) => {
        const offering = offeringsById[quotePrice.offering.id];
        const isPoTOfferingType =
          offering?.type === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL;

        return (
          <QuotePriceSchedules
            key={quotePrice.quoteOfferingId}
            quotePrice={quotePrice}
            isPoTOfferingType={isPoTOfferingType}
            currency={currency}
          />
        );
      })}
    </>
  );
};

export default PricesSectionContent;
