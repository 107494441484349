import { useEffect, useMemo, useState } from 'react';
import { setRecoil } from 'recoil-nexus';
import { useGetById } from '../api/queryUtils';
import { logger } from '../services/logger';
import { activeToastState } from '../store/global.store';
import {
  IOfferingRes,
  IQuoteOfferingRespSchema,
  SelectedProductsWithinOffering,
  SelectedProductsWithinOfferingForm,
} from '../types';
import { sortByProductType } from '../utils';
import { arrayToObject } from '../utils/misc';
import { useNonInitialEffect } from './useNonInitialEffect';

interface UseProductSelectionParams {
  offering: IOfferingRes;
  onSave: (data: SelectedProductsWithinOfferingForm) => void;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  onDrawerClose?: () => void;
  setIsProductListChanged?: (val: boolean) => void;
}

export const useProductSelection = ({
  offering,
  onSave,
  quoteOffering,
  onDrawerClose,
  setIsProductListChanged,
}: UseProductSelectionParams) => {
  const [searchTerm, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [rootProductValue, setRootProductValue] = useState<
    SelectedProductsWithinOffering[]
  >([]);
  const [visibleRows, setVisibleRows] = useState<
    SelectedProductsWithinOffering[]
  >([]);

  const offeringProduct = useMemo(
    () => offering.offeringProducts.map((item: any) => item.product),
    [offering.offeringProducts],
  );

  const { quoteItemsByProductId } = useMemo(
    () => ({
      quoteItemsByProductId: arrayToObject(offeringProduct, 'id'),
    }),
    [offeringProduct],
  );

  const isAnyProductSelected = useMemo(() => {
    return rootProductValue.some((item) => item.isSelected);
  }, [rootProductValue]);

  const { data: products, isLoading } = useGetById<
    IOfferingRes,
    SelectedProductsWithinOffering[]
  >('productCatalogOfferings', offering.id, {
    refetchOnWindowFocus: false,
    select: (data) =>
      sortByProductType(
        data.offeringProducts.map(({ isMandatory, product }) => {
          const existingItemsId =
            quoteOffering?.items.map((item) => item.productId) ?? [];

          const hasQuoteItem = !!quoteItemsByProductId[product.id];
          const shouldSelect = existingItemsId.includes(product.id);

          return {
            id: product.id,
            quoteItemId: quoteItemsByProductId[product.id]?.id,
            name: product.name,
            description: product.description,
            productType: product.productType,
            isMandatory,
            isSelected: shouldSelect ? hasQuoteItem : isMandatory,
          };
        }),
        'name',
      ),
  });

  useEffect(() => {
    if (products) {
      setRootProductValue(products);
      setVisibleRows(products);
    }
  }, [products]);

  const existingVisibleRow = (id: string) =>
    visibleRows.find((row) => row.id === id);

  const isAnyChangesMade = useMemo(() => {
    const nonMandatoryProducts = (products ?? []).filter(
      (product) => !product.isMandatory,
    );

    const result = nonMandatoryProducts.some((product) => {
      const rootProduct = rootProductValue.find((row) => row.id === product.id);
      return rootProduct && rootProduct.isSelected !== product.isSelected;
    });

    return result;
  }, [rootProductValue, products]);

  useNonInitialEffect(() => {
    setIsProductListChanged?.(isAnyChangesMade);
  }, [isAnyChangesMade, setIsProductListChanged]);

  useNonInitialEffect(() => {
    setIsSearching(false);
    if (!searchTerm) {
      setVisibleRows(
        rootProductValue.map((product) => {
          const existingRowValue = existingVisibleRow(product.id);
          return {
            ...product,
            isSelected: existingRowValue
              ? existingRowValue.isSelected
              : product.isSelected,
          };
        }),
      );
    } else {
      const searchTermLowercase = searchTerm.toLowerCase();

      const searchProps: (keyof Omit<
        SelectedProductsWithinOffering,
        'isMandatory' | 'isSelected'
      >)[] = ['name', 'description', 'productType'];

      setVisibleRows(
        (rootProductValue ?? [])
          .filter((product) => {
            return searchProps.some((prop) =>
              product[prop]?.toLowerCase().includes(searchTermLowercase),
            );
          })
          .map((product) => {
            const existingRowValue = existingVisibleRow(product.id);

            return {
              ...product,
              isSelected: existingRowValue?.isSelected ?? product.isSelected,
            };
          }),
      );
    }
    setIsSearching(true);
  }, [searchTerm, rootProductValue]);

  const isAllProductSelected = visibleRows.every(
    ({ isSelected }) => isSelected,
  );
  const isIndeterminate =
    !isAllProductSelected && visibleRows.some(({ isSelected }) => isSelected);

  const selectedProductCount = useMemo(() => {
    return rootProductValue.filter((item) => item.isSelected).length;
  }, [rootProductValue]);

  const handleSelectAll = (value: boolean) => {
    if (visibleRows.length === 0) return;

    const sharedData = visibleRows.map((row) => ({
      ...row,
      isSelected: row.isMandatory ? true : value,
    }));
    setVisibleRows(sharedData);
    setRootProductValue((prevRootProduct) =>
      prevRootProduct.map((row) => ({
        ...row,
        isSelected: row.isMandatory || value,
      })),
    );
  };

  const onSubmit = () => {
    const offeringItems = rootProductValue.filter(
      (item) => item.isSelected || item.isMandatory,
    );

    if (offeringItems.length === 0) {
      setRecoil(activeToastState, {
        life: 5000,
        severity: 'error',
        summary: 'Error',
        detail: 'Quote Offering must have at least one quote item.',
      });
      return;
    }

    onSave({ products: rootProductValue });
    setIsProductListChanged?.(false);
  };

  const onError = (error: unknown) => {
    logger.error('Error submitting product selection form', error);
  };

  const handleCheckboxChange = (productId: string, value: boolean) => {
    const updateSelection = (rows: SelectedProductsWithinOffering[]) =>
      rows.map((row) =>
        row.id === productId
          ? { ...row, isSelected: row.isMandatory ? true : value }
          : row,
      );

    setVisibleRows((prevVisibleRows) => updateSelection(prevVisibleRows));
    setRootProductValue((prevRootProductValue) =>
      updateSelection(prevRootProductValue),
    );
  };

  const setSearchTerm = (term: string) => {
    setSearch(term);
  };

  return {
    selectedProductCount,
    rootProductValue,
    isAnyProductSelected,
    isAnyChangesMade,
    searchTerm,
    visibleRows,
    isAllProductSelected,
    isIndeterminate,
    isLoading,
    isSearching,
    handleCheckboxChange,
    setSearchTerm,
    setVisibleRows,
    handleSelectAll,
    onSubmit,
    onError,
  };
};
