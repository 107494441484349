import { FunctionComponent } from 'react';
import { METABASE_CONFIG } from '../../config';
import { MBox } from '../Monetize';

interface EmbeddedMetabaseProps {}

export const LogoutMetabase: FunctionComponent<any> = (
  props: EmbeddedMetabaseProps,
) => {
  return (
    <MBox style={{ display: 'none' }}>
      <iframe
        src={METABASE_CONFIG.logoutUrl}
        style={{ width: '100%', height: '100%' }}
      />
    </MBox>
  );
};
