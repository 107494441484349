import clamp from 'lodash/clamp';
import { useEffect, useReducer, useState } from 'react';
import { handleApiErrorToast } from '../../../../../api/axios';
import { doCreateQuoteOffering } from '../../../../../api/cpqService';
import { useActiveGuidedQuotingProcess } from '../../../../../api/guidedQuotingService';
import {
  doGetOfferingById,
  doGetOfferingRate,
} from '../../../../../api/productCatalogService';
import { ensureArray } from '../../../../../api/utils';
import { useNetTerms, useQuote } from '../../../../../hooks';
import { useFlags } from '../../../../../services/launchDarkly';
import { logger } from '../../../../../services/logger';
import { useToast } from '../../../../../services/toast';
import {
  ContextProduct,
  GuidedQuotingContext,
  GuidedQuotingContextOffering,
  IAccountRespSchema,
  IQuestion,
  IQuoteItemReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  Maybe,
  QuestionTypesEnum,
} from '../../../../../types';
import { TEMP_SALES_DEMO_MEDINSIGHT_calculateCustomPriceFormulaInGuidedSelling } from '../../../../../types/sales-demo.utils';
import {
  ensureQuantityWithinRateBounds,
  getQuoteCreatePayload,
} from './guidedQuote.utils';

export interface UseGuidedQuoteProcessProps {
  disabled?: boolean;
  /**
   * This is required to submit the flow
   * allow nullish to make it easy to work with places where the account is not yet loaded/selected
   */
  account: Maybe<IAccountRespSchema>;
  initialQuoteData?: Partial<IQuoteRequestSchema>;
  getInitialQuoteFieldValues?: () => Partial<IQuoteRequestSchema>;
  onQuoteCreated?: (quote: IQuoteRespSchema) => Promise<void>;
  /**
   * Callback called when the quote+offerings have been created
   */
  onFinished: (quote: IQuoteRespSchema) => void;
}

interface State {
  questions: IQuestion[];
  firstQuestion: IQuestion | null;
  lastQuestion: IQuestion | null;
  currentQuestion: IQuestion | null;
  hasPreviousQuestion: boolean;
  previousQuestion: IQuestion | null;
  nextQuestion: IQuestion | null;
  hasNextQuestion: boolean;
  offeringQuestion: IQuestion | null;
  productQuestion: IQuestion | null;
  progress: number;
}

type Action =
  | {
      type: 'INIT';
      payload: {
        // Set to false to disable the guided quoting process - will ignore all actual
        disabled?: boolean;
        questions: IQuestion[];
        initialQuestion?: IQuestion;
      };
    }
  | {
      type: 'CHANGE_QUESTION';
      payload: { direction: 'back' | 'forward' };
    };

const getInitialState = (
  questions: IQuestion[],
  disabled: boolean = false,
): State => {
  questions = questions.map((question) => ({
    ...question,
    // FIXME: we should not need this after migration to rules service data model
    compareTo: question.compareTo
      ? ensureArray(question.compareTo)
      : question.compareTo,
  }));
  const currentQuestion = disabled ? null : questions[0];
  return {
    questions,
    firstQuestion: questions[0],
    lastQuestion: questions[questions.length - 1],
    currentQuestion,
    offeringQuestion:
      questions.find(({ type }) => type === QuestionTypesEnum.OFFERING) ?? null,
    productQuestion:
      questions.find(({ type }) => type === QuestionTypesEnum.RATE) ?? null,
    ...calculateQuestionState(questions, currentQuestion?.id),
  };
};

const getPriorOrNextQuestion = (
  questions: IQuestion[],
  currentQuestionId: string,
  direction: 'back' | 'forward',
) => {
  const indexChange = direction === 'forward' ? 1 : -1;
  return questions[
    clamp(
      questions.findIndex(({ id }) => id === currentQuestionId) + indexChange,
      0,
      questions.length - 1,
    )
  ];
};

const calculateQuestionState = (
  questions: IQuestion[],
  currentQuestionId?: string,
): Pick<
  State,
  | 'previousQuestion'
  | 'nextQuestion'
  | 'hasPreviousQuestion'
  | 'hasNextQuestion'
  | 'progress'
> => {
  const currentQuestionIdx = questions.findIndex(
    ({ id }) => id === currentQuestionId,
  );
  const previousQuestion =
    currentQuestionIdx > 0 ? questions[currentQuestionIdx - 1] : null;
  const nextQuestion = questions[currentQuestionIdx + 1] ?? null;
  return {
    previousQuestion,
    nextQuestion,
    hasPreviousQuestion: !!previousQuestion,
    hasNextQuestion: !!nextQuestion,
    progress:
      questions.length > 0
        ? (Math.max(currentQuestionIdx, 0) / questions.length) * 100
        : 0,
  };
};

export const questionStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INIT': {
      const { disabled, questions } = action.payload;
      return getInitialState(questions, disabled);
    }
    case 'CHANGE_QUESTION': {
      const { currentQuestion: priorCurrentQuestion, questions } = state;
      if (!priorCurrentQuestion) {
        return state;
      }
      const { direction } = action.payload;
      const currentQuestion = getPriorOrNextQuestion(
        questions,
        priorCurrentQuestion.id,
        direction,
      );
      return {
        ...state,
        currentQuestion,
        ...calculateQuestionState(state.questions, currentQuestion.id),
      };
    }
    default:
      throw new Error('Invalid action');
  }
};

/**
 * Hook to facilitate the guided quoting process
 * Manages state of going forward and backward through questions
 * And handles the final quote creation process
 */
export const useGuidedQuoteProcess = ({
  disabled = false,
  account,
  initialQuoteData,
  getInitialQuoteFieldValues,
  onQuoteCreated,
  onFinished,
}: UseGuidedQuoteProcessProps) => {
  const { addToast } = useToast();
  const { createQuote } = useQuote();
  const [isLoading, setIsLoading] = useState(false);
  const { salesDemoMedinsightTempFormulaCustomPricing } = useFlags();

  // TODO: probably store in useReducer
  const [context, setContext] = useState<GuidedQuotingContext>({});
  const { data: guidedQuoting } = useActiveGuidedQuotingProcess();
  const { defaultNetTerm } = useNetTerms();

  // TODO: we can most likely trim this down to just a few fields
  const [
    {
      currentQuestion,
      firstQuestion,
      lastQuestion,
      nextQuestion,
      previousQuestion,
      questions,
      hasNextQuestion,
      hasPreviousQuestion,
      offeringQuestion,
      productQuestion,
      progress,
    },
    dispatch,
  ] = useReducer(
    questionStateReducer,
    getInitialState(guidedQuoting?.questions ?? [], disabled),
  );

  useEffect(() => {
    if (guidedQuoting && !disabled) {
      dispatch({
        type: 'INIT',
        payload: getInitialState(guidedQuoting?.questions ?? [], disabled),
      });

      const newContext: GuidedQuotingContext = {};
      guidedQuoting.questions.forEach((question: any) => {
        switch (question.type) {
          case QuestionTypesEnum.CURRENCY: {
            newContext[question.id] = {
              type: question.type,
              value: initialQuoteData?.currency ?? '',
            };
            break;
          }
          case QuestionTypesEnum.LEGAL_ENTITY: {
            newContext[question.id] = {
              type: question.type,
              value: initialQuoteData?.legalEntityId ?? '',
            };
            break;
          }
          case QuestionTypesEnum.DATE:
          case QuestionTypesEnum.NUMBER:
          case QuestionTypesEnum.PRODUCT:
          case QuestionTypesEnum.TEXT: {
            newContext[question.id] = { type: question.type, value: '' };
            break;
          }
          case QuestionTypesEnum.BILLING_FREQUENCY: {
            newContext[question.id] = { type: question.type, value: [] };
            break;
          }
          case QuestionTypesEnum.OFFERING:
          case QuestionTypesEnum.RATE: {
            newContext[question.id] = { type: question.type, value: {} };
            newContext[question.id] = { type: question.type, value: {} };
            break;
          }
        }
        setContext(newContext);
      });
    }
    // explicitly not re-running if initial quote data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, guidedQuoting]);

  const onPreviousQuestion = () => {
    if (!account || !hasPreviousQuestion || !currentQuestion) {
      return;
    }
    if (hasPreviousQuestion) {
      dispatch({ type: 'CHANGE_QUESTION', payload: { direction: 'back' } });

      // TODO: this is sketchy - and this is why this should be stored in our reducer state
      // Ideally we can know when to nuke state and when to keep state from prior questions
      // e.x. "currency" - if already selected then we don't need to nuke it
      // and ideally we have state for all questions in our context even before the user gets to them
      const {
        [currentQuestion!.id]: _,
        //[lastQuestion.id]: __,
        ...oldContext
      } = context;
      setContext(oldContext);
    }
  };

  const onNextQuestion = () => {
    if (!account || !currentQuestion) {
      return;
    }
    if (hasNextQuestion) {
      dispatch({ type: 'CHANGE_QUESTION', payload: { direction: 'forward' } });
    } else {
      onFinishedAllSteps();
    }
  };

  // When the user answers a question
  const onFinishedAllSteps = async () => {
    if (!guidedQuoting || !account || !currentQuestion) {
      // TODO: this is an error state - figure out how to handle
      return;
    }

    setIsLoading(true);

    const quoteCreatePayload: IQuoteRequestSchema = getQuoteCreatePayload(
      guidedQuoting,
      account,
      context,
      { netTerms: defaultNetTerm?.value ?? undefined },
      getInitialQuoteFieldValues,
    );

    try {
      const createdQuote = await createQuote(quoteCreatePayload);

      if (!createdQuote) {
        return;
      }

      if (onQuoteCreated) {
        await onQuoteCreated(createdQuote);
      }

      try {
        const createdQuoteOfferings: IQuoteOfferingRespSchema[] = [];

        const quoteOfferingQuestion = Object.values(context).find(
          ({ type }) => type === QuestionTypesEnum.OFFERING,
        ) as GuidedQuotingContextOffering;

        if (createdQuote && guidedQuoting && quoteOfferingQuestion) {
          for (const offeringId in quoteOfferingQuestion.value) {
            const data: {
              name: string;
              rate: string;
              products: ContextProduct[];
            } = quoteOfferingQuestion.value[offeringId];

            // if no products are selected, don't create this offering
            if (data.products.every(({ isSelected }) => !isSelected)) {
              continue;
            }

            const offeringData = await doGetOfferingById(offeringId);

            const items = data.products.map((product): IQuoteItemReqSchema => {
              let quantity =
                !isNaN(Number(product.qty)) && Number(product.qty) >= 0
                  ? Number(product.qty)
                  : 1;
              // FIXME: handle this better once we have mandatory/optional support
              if (!product.isSelected) {
                quantity = 0;
              }
              return {
                productId: product.id,
                quantity,
                customDiscountAmountOrPercent: null,
              };
            });

            // Probably not needed, but just make sure all products within the offering are included
            // TODO: this will break with optional/mandatory products
            for (const product of offeringData.products) {
              if (!items.find((item) => item.productId === product.id)) {
                items.push({
                  productId: product.id,
                  quantity: 0,
                  customDiscountAmountOrPercent: null,
                });
              }
            }

            const payload: IQuoteOfferingReqSchema = {
              offeringId: offeringId,
              rateId: data.rate,
              items: items,
            };

            try {
              createdQuoteOfferings.push(
                await doCreateQuoteOffering(createdQuote.id, payload),
              );
            } catch (error) {
              // if the quantity was not within the valid range, we need to adjust it
              const rateData = await doGetOfferingRate(data.rate);
              createdQuoteOfferings.push(
                await doCreateQuoteOffering(createdQuote.id, {
                  offeringId: offeringId,
                  rateId: data.rate,
                  items: ensureQuantityWithinRateBounds(items, rateData),
                }),
              );
            }
          }

          if (salesDemoMedinsightTempFormulaCustomPricing) {
            await TEMP_SALES_DEMO_MEDINSIGHT_calculateCustomPriceFormulaInGuidedSelling(
              {
                salesDemoMedinsightTempFormulaCustomPricing,
                quoteOfferings: createdQuoteOfferings,
              },
            );
          }

          onFinished(createdQuote);
        } else if (createdQuote) {
          onFinished(createdQuote);
        }
      } catch (ex) {
        // TODO: ask the user if they want to start over or if they want to go to the quote
        if (createdQuote) {
          onFinished(createdQuote);
        }
        addToast({
          summary: 'Error',
          detail:
            'One or more of your quote offerings were unable to be created',
          severity: 'warning',
        });
      } finally {
        setIsLoading(false);
      }
    } catch (ex) {
      logger.error('Error creating quote', ex);
      handleApiErrorToast(ex);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    guidedQuoting,
    // reducer state
    currentQuestion,
    firstQuestion,
    lastQuestion,
    nextQuestion,
    previousQuestion,
    questions,
    hasNextQuestion,
    hasPreviousQuestion,
    offeringQuestion,
    productQuestion,
    progress,
    // other state
    isLoading,
    setIsLoading,
    context,
    // Actions
    onPreviousQuestion,
    onNextQuestion,
    setContext,
  };
};
