import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  EntityGroupEnum,
  IRuleFormResponse,
} from '../routes/Settings/rules-v3/rules.types';
import { ICustomFieldResSchema, RuleTypeEnum } from '../types';
import { apiGetAllList, apiPost, apiPut } from './axios';
import { ApiQueryItem } from './queryUtils';
import { asQueryUtil } from './utils';

const baseRulesKey = ['rules'] as const;

export const rulesV3ServiceQueryKeys = {
  base: [...baseRulesKey, 'rules-v3'] as const,
  uiFormData: (type: RuleTypeEnum, entityGroupType: EntityGroupEnum) =>
    [
      ...rulesV3ServiceQueryKeys.base,
      'ui-form',
      type,
      entityGroupType,
    ] as const,
};

export const approvalV3ServiceQueryKeys = {
  base: [...baseRulesKey, 'approvals-v3'] as const,
  list: () => [...approvalV3ServiceQueryKeys.base, 'list'] as const,
  allList: () => [...approvalV3ServiceQueryKeys.base, 'allList'] as const,
  ruleById: (id: string) => [...approvalV3ServiceQueryKeys.base, id] as const,
};

const approvalsV3QueryItem: Required<Omit<ApiQueryItem, 'delete' | 'upload'>> =
  {
    list: {
      endpoint: `/rules-v3/rules`,
      queryKey: approvalV3ServiceQueryKeys.list(),
      byIdQueryKey: (id: string) => approvalsV3QueryItem.byId.queryKey(id),
    },
    byId: {
      endpoint: (id: string) => `/rules-v3/rules/${id}`,
      queryKey: (id: string) => approvalV3ServiceQueryKeys.ruleById(id),
    },
    create: {
      endpoint: () => `/rules-v3/rules`,
      invalidateKeys: [approvalV3ServiceQueryKeys.list()],
      setDataKey: (id: string) => approvalV3ServiceQueryKeys.ruleById(id),
    },
    update: {
      endpoint: (id: string) => `/rules-v3/rules/${id}`,
      invalidateKeys: [approvalV3ServiceQueryKeys.list()],
      setDataKey: (id: string) => approvalV3ServiceQueryKeys.ruleById(id),
    },
  };

export const validationV3ServiceQueryKeys = {
  base: [...baseRulesKey, 'validations-v3'] as const,
  list: () => [...validationV3ServiceQueryKeys.base, 'list'] as const,
  ruleById: (id: string) => [...validationV3ServiceQueryKeys.base, id] as const,
};

const validationsV3QueryItem: Required<
  Omit<ApiQueryItem, 'delete' | 'upload'>
> = {
  list: {
    endpoint: `/rules-v3/rules`,
    queryKey: validationV3ServiceQueryKeys.list(),
    byIdQueryKey: (id: string) => validationsV3QueryItem.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/rules-v3/rules/${id}`,
    queryKey: (id: string) => validationV3ServiceQueryKeys.ruleById(id),
  },
  create: {
    endpoint: () => `/rules-v3/rules`,
    invalidateKeys: [validationV3ServiceQueryKeys.list()],
    setDataKey: (id: string) => validationV3ServiceQueryKeys.ruleById(id),
  },
  update: {
    endpoint: (id: string) => `/rules-v3/rules/${id}`,
    invalidateKeys: [validationV3ServiceQueryKeys.list()],
    setDataKey: (id: string) => validationV3ServiceQueryKeys.ruleById(id),
  },
};

export const conditionalTermV3ServiceQueryKeys = {
  base: [...baseRulesKey, 'conditionalTerms-v3'] as const,
  list: () => [...validationV3ServiceQueryKeys.base, 'list'] as const,
  ruleById: (id: string) => [...validationV3ServiceQueryKeys.base, id] as const,
};

const conditionalTermsV3QueryItem: Required<
  Omit<ApiQueryItem, 'delete' | 'upload'>
> = {
  list: {
    endpoint: `/rules-v3/rules`,
    queryKey: validationV3ServiceQueryKeys.list(),
    byIdQueryKey: (id: string) => conditionalTermsV3QueryItem.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/rules-v3/rules/${id}`,
    queryKey: (id: string) => validationV3ServiceQueryKeys.ruleById(id),
  },
  create: {
    endpoint: () => `/rules-v3/rules`,
    invalidateKeys: [validationV3ServiceQueryKeys.list()],
    setDataKey: (id: string) => validationV3ServiceQueryKeys.ruleById(id),
  },
  update: {
    endpoint: (id: string) => `/rules-v3/rules/${id}`,
    invalidateKeys: [validationV3ServiceQueryKeys.list()],
    setDataKey: (id: string) => validationV3ServiceQueryKeys.ruleById(id),
  },
};

export function useArchiveRuleV3(
  options: Partial<
    UseMutationOptions<
      undefined,
      unknown,
      {
        ruleId: string;
        type: RuleTypeEnum;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    undefined,
    unknown,
    {
      ruleId: string;
      type: RuleTypeEnum;
    }
  >({
    mutationFn: ({ ruleId, type }) =>
      apiPut(`/rules-v3/rules/${ruleId}/archive`, {}).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      const { ruleId, type } = variables;
      if (type === RuleTypeEnum.APPROVAL) {
        queryClient.invalidateQueries({
          queryKey: approvalV3ServiceQueryKeys.list(),
        });
        queryClient.invalidateQueries({
          queryKey: approvalV3ServiceQueryKeys.ruleById(ruleId),
        });
        queryClient.invalidateQueries({
          queryKey: approvalV3ServiceQueryKeys.allList(),
        });
      } else if (type === RuleTypeEnum.CONDITIONAL_TERM) {
        queryClient.invalidateQueries({
          queryKey: validationV3ServiceQueryKeys.list(),
        });
        queryClient.invalidateQueries({
          queryKey: conditionalTermV3ServiceQueryKeys.ruleById(ruleId),
        });
      } else if (type === RuleTypeEnum.VALIDATION) {
        queryClient.invalidateQueries({
          queryKey: validationV3ServiceQueryKeys.list(),
        });
        queryClient.invalidateQueries({
          queryKey: validationV3ServiceQueryKeys.ruleById(ruleId),
        });
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export const RULE_SERVICE_V3_API = asQueryUtil({
  'approvals-v3': approvalsV3QueryItem,
  'validations-v3': validationsV3QueryItem,
  'conditionalTerms-v3': conditionalTermsV3QueryItem,
});

/**
 * Get all data for Rules V3 UI
 * This first fetches custom fields, then sends that to the rules service
 * to get all the data needed to render the UI
 */
export function useGetRuleFormData({
  type,
  entityGroupType,
}: {
  type: RuleTypeEnum;
  entityGroupType: EntityGroupEnum;
}) {
  return useQuery({
    queryKey: [...rulesV3ServiceQueryKeys.uiFormData(type, entityGroupType)],
    queryFn: () => {
      return apiGetAllList<ICustomFieldResSchema>(
        '/api/configurations/customFields',
      )
        .then((customFields) =>
          apiPost<IRuleFormResponse>(
            '/rules-v3/ui-data',
            { customFields },
            {
              params: { type: 'QUOTE' },
            },
          ),
        )
        .then((res) => res.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    select: (data) => {
      return {
        ruleFormData: data,
        entities: Object.values(data.fields)?.map(({ entity }) => entity),
      };
    },
  });
}
