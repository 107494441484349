import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { handleApiErrorToast } from '../api/axios';
import {
  doCreateOfferingRate,
  doDeleteRate,
  doGetOfferingRate,
  doUpdateOfferingRate,
  productCatalogServiceQueryKeys,
} from '../api/productCatalogService';
import {
  IAccountRateCreateReqSchema,
  IAccountRateUpdateReqSchema,
  IPriceUnderProductSchemaUI,
  IRateReqSchema,
  IRateResSchema,
} from '../types';
import { nullifyEmptyStrings } from '../utils/misc';
import { getOrderedPricesUnderProduct } from '../utils/product';

// Cache requests to avoid making multiple requests for the same rateId
const inFlightRequests = new Map<string, Promise<IRateResSchema | null>>();
const cache = new Map<string, IRateResSchema>();

const useOfferingRate = (id?: string) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [offeringRate, setOfferingRate] = useState<IRateResSchema | null>(null);
  const [orderedPricesUnderProduct, setOrderedPricesUnderProduct] = useState<
    IPriceUnderProductSchemaUI[]
  >([]);

  const fetchOfferingRate: (
    rateId: string,
  ) => Promise<IRateResSchema | null> = async (
    rateId: string,
  ): Promise<IRateResSchema | null> => {
    // Check if the result is already cached
    if (cache.has(rateId)) {
      const response = cache.get(rateId)!;
      setOrderedPricesUnderProduct(getOrderedPricesUnderProduct(response));
      setOfferingRate(response);
      return response;
    }

    // Check react query for catch
    const reactQueryCachedItem = queryClient.getQueryData<IRateResSchema>(
      productCatalogServiceQueryKeys.ratesById(rateId),
    );
    if (reactQueryCachedItem) {
      const response = reactQueryCachedItem;
      setOrderedPricesUnderProduct(getOrderedPricesUnderProduct(response));
      setOfferingRate(response);
      cache.set(rateId, response); // set in local cache
      return response;
    }

    // Check if there's an in-flight request for the same rateId
    if (inFlightRequests.has(rateId)) {
      setIsFetching(true);
      const response = await inFlightRequests.get(rateId)!;
      if (response) {
        setOrderedPricesUnderProduct(getOrderedPricesUnderProduct(response));
      }
      setOfferingRate(response);
      setIsFetching(false);
      return response;
    }

    // Handle request
    const promise = (async () => {
      setIsFetching(true);
      try {
        const res = await doGetOfferingRate(rateId);
        setOrderedPricesUnderProduct(getOrderedPricesUnderProduct(res));
        setOfferingRate(res);
        cache.set(rateId, res); // Cache the result
        queryClient.setQueryData(
          productCatalogServiceQueryKeys.ratesById(res.id),
          res,
        );
        return res;
      } catch (error) {
        handleApiErrorToast(error);
        return null;
      } finally {
        setIsFetching(false);
        inFlightRequests.delete(rateId); // Remove the in-flight request from the map
      }
    })();
    inFlightRequests.set(rateId, promise);
    return promise;
  };

  const createOfferingRate = async (
    data: IRateReqSchema | IAccountRateCreateReqSchema,
    offeringId: string | undefined = id,
  ): Promise<IRateResSchema | null> => {
    if (!offeringId) {
      throw new Error('offering id is required to create rate');
    }

    setLoading(true);

    try {
      const res = await doCreateOfferingRate(
        nullifyEmptyStrings({
          ...data,
          id: undefined, // id and customId are set with a fake UUID
          customId: undefined, // id and customId are set with a fake UUID
        }),
        offeringId,
      );

      cache.set(res.id, res); // Cache the result
      setOfferingRate(res);
      queryClient.invalidateQueries({
        queryKey: productCatalogServiceQueryKeys.orderables(),
      });
      return res;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateOfferingRate = async (
    data: IRateReqSchema | IAccountRateUpdateReqSchema,
    rateId: string,
    offeringId: string | undefined = id,
  ): Promise<IRateResSchema | null> => {
    if (!offeringId) {
      throw new Error('offering id is required to update rate');
    }

    setLoading(true);

    try {
      const res = await doUpdateOfferingRate(
        nullifyEmptyStrings(data),
        offeringId,
        rateId,
      );

      setOfferingRate(res);
      cache.set(res.id, res); // Update cache for rate
      queryClient.invalidateQueries({
        queryKey: productCatalogServiceQueryKeys.orderables(),
      });
      return res;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const removeOfferingRate = async (rateId: string): Promise<boolean> => {
    setRemoveLoading(true);
    try {
      const res = await doDeleteRate(rateId);

      setOfferingRate(res);
      return true;
    } catch (error) {
      handleApiErrorToast(error);
      return false;
    } finally {
      setRemoveLoading(false);
    }
  };

  return {
    isFetching,
    loading,
    removeLoading,
    offeringRate,
    orderedPricesUnderProduct,
    setOrderedPricesUnderProduct,
    setOfferingRate,
    fetchOfferingRate,
    createOfferingRate,
    updateOfferingRate,
    removeOfferingRate,
  };
};

export default useOfferingRate;
