import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useGetQuoteStorageById } from '../../../../api/cpqService';
import { MAccordion, MBox, MPageLoader } from '../../../../components/Monetize';
import { DeltaviewFailureExplanation } from '../../../../components/Quotes/DeltaviewFailureExplanation';
import { useDocumentHead } from '../../../../services/documentHead';
import { IQuoteRequestSchema } from '../../../../types';
import { ensureBoolean } from '../../../../utils';
import { ReviewQuoteHtml } from '../../Quote/ReviewQuoteHtml';
import { QuoteConfetti } from '../../Quote/components/QuoteConfetti';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteBasic } from './QuoteBasic';
import { QuotePageCustomerBar } from './QuotePageCustomerBar';

interface QuotePageCustomerProps {
  setContactDrawerOpen: (val: boolean) => void;
}
export const QuotePageCustomer = ({
  setContactDrawerOpen,
}: QuotePageCustomerProps) => {
  const {
    quoteData: { quote, displayConfig },
    setReviewQuoteDisplayWidth,
  } = useQuoteContext();
  const reviewQuoteRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const isCelebrate = searchParams.get('celebrate');
  const { data: document } = useGetQuoteStorageById(
    {
      quoteId: quote?.id!,
      storageId: quote?.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote?.id && !!quote?.documentLocation },
  );

  const { setValue } = useFormContext<IQuoteRequestSchema>();
  // NOTE: Leave these setDocTitle within the index.tsx and QuotePageCustomer.tsx
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle(
      `Review Quote${quote?.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description]);

  useEffect(() => {
    if (reviewQuoteRef.current) {
      // Calculating review quote display width and removing padding
      setReviewQuoteDisplayWidth(reviewQuoteRef.current.clientWidth - 64);
    }
  }, [reviewQuoteRef, document?.contentBase64Encoded]);

  if (!quote || !displayConfig) {
    return <MPageLoader />;
  }

  return (
    <>
      <QuoteConfetti active={ensureBoolean(isCelebrate)} />
      <MAccordion
        w="100%"
        my={4}
        allowToggle
        variant="outline"
        defaultIndex={[]}
      >
        <QuoteBasic
          onOpenContactDrawer={() => {
            setContactDrawerOpen(true);
          }}
        />
      </MAccordion>

      {!!quote && <QuotePageCustomerBar quote={quote} />}

      <DeltaviewFailureExplanation
        quote={quote}
        displayConfig={displayConfig}
      />
      <MBox
        w="full"
        borderWidth={0.5}
        borderColor="tBlue.hover"
        background="tWhite.titanWhite"
        ref={reviewQuoteRef}
      >
        {!!quote && !!displayConfig && (
          <ReviewQuoteHtml quote={quote} displayConfig={displayConfig} />
        )}
      </MBox>
    </>
  );
};
