import * as Sentry from '@sentry/react';
import { ENVIRONMENT, RELEASE, SENTRY } from '../config';

// Don't initialize sentry for localhost
// we were using the enabled flag in the past, but this caused lots of console logs
if (!ENVIRONMENT.IS_LOCAL_DEV && !ENVIRONMENT.IS_PLAYWRIGHT) {
  Sentry.init({
    dsn: SENTRY.dsn,
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Don't capture sessions unless there is an actual error
    replaysSessionSampleRate: 0,
    // Always capture session if there is an error
    replaysOnErrorSampleRate: 1.0,
    environment: ENVIRONMENT.HOST_TYPE,
    attachStacktrace: true,
    release: RELEASE.releaseVersion,
    beforeSend: (event, hint) => {
      // Ensure app crashes caught by error boundary are flagged as unhandled
      const fromGlobalCrash = event.exception?.values?.some(
        (value) => value.type === 'React ErrorBoundary Error',
      );
      if (fromGlobalCrash) {
        event.exception?.values?.forEach((value) => {
          if (value.mechanism?.handled) {
            value.mechanism.handled = false;
          }
        });
      }
      return event;
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      /https?:\/\/((cdn|www)\.)?testrigor\.com/,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });
}

export default Sentry;
