import { formatInTimeZone, toDate } from 'date-fns-tz';
import { addMinutes } from 'date-fns/addMinutes';
import { format as formatDate } from 'date-fns/format';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { isSameDay } from 'date-fns/isSameDay';
import { isValid } from 'date-fns/isValid';
import { parse as parseDate } from 'date-fns/parse';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { subMinutes } from 'date-fns/subMinutes';
import isString from 'lodash/isString';
import { z } from 'zod';
import {
  DateFormatEnum,
  ISO_COMPATIBLE_DATE,
  NOT_NUMERIC,
  TWELVE_HR_TIME,
  TWENTY_FOUR_HR_TIME,
} from '../constants/common';
import { USER_TIMEZONE } from '../constants/dates';
import { logger } from '../services/logger';
import Sentry from '../services/sentry';
import { Maybe } from '../types';
import { TimeZoneOption } from '../types/timezoneTypes';

/**
 * Synced globally (via global.store.ts) outside of react context to ensure that the timezone is consistent across the app
 * and so that individual components do not need concern themselves with this.
 * This is used for formatting dates in the tenant's timezone.
 */
export const TIMEZONE_CONFIG = {
  // this will be updated on tenant change
  tenantTimezone: USER_TIMEZONE,
  userTimezone: USER_TIMEZONE,
  UTC: 'UTC',
  /**
   * Initialized in useConfigDataFetcher
   * This can be used to access the full timezone information for a given timezone value
   */
  timezonesByValue: {} as Record<string, TimeZoneOption>,
};

export type TimezoneConfigKey = keyof Pick<
  typeof TIMEZONE_CONFIG,
  'tenantTimezone' | 'userTimezone' | 'UTC'
>;
const TIMEZONE_DEFAULT = 'tenantTimezone';

/**
 *  Format the given date to long (MMMM d, yyyy) and then returned the formatted result.
 *
 * @param {string | null | Date} date - The input to be formatted.
 * @returns {string} The formatted date e.g. November 30, 2021 (if valid) or empty string (if not valid)
 */
export const toDateLong = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
): string => {
  return getFormattedDate(date, 'MMMM d, yyyy', TIMEZONE_CONFIG[timezone]);
};

/**
 * Format the given date to short (MMM d, yyyy) and then returned the formatted result.
 *
 * @param {string | null | Date } date - The input to be formatted.
 * @returns {string} The formatted date e.g. Nov 30, 2021 (if valid) or empty string (if not valid)
 */
export const toDateShort = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(date, 'MMM d, yyyy', TIMEZONE_CONFIG[timezone]);
};

/**
 * Format the given date to the short (PPp) for both the date and time, and then return the formatted result.
 *
 * @param {string | null | Date } date - The input to be formatted.
 * @returns {string} The formatted date e.g. Mar 9, 2023, 6:27 AM (if valid) or empty string (if not valid)
 */
export const toDateTimeShort = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(date, 'PPp', TIMEZONE_CONFIG[timezone]);
};

/**
 * Format the given date to only include the date (yyyy-MM-dd), and then return the formatted result.
 *
 * @param {string | null | Date | undefined} date - The input to be formatted.
 * @returns {string} The formatted date e.g. 2023-03-09 (if valid) or empty string (if not valid)
 */

export const toDateOnly = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(date, 'yyyy-MM-dd', TIMEZONE_CONFIG[timezone]);
};
/**
 * Format the given date to only include the date in MM-dd-yyyy format, and then return the formatted result.
 *
 * @param {string | null | Date} date - The input to be formatted.
 * @returns {string} The formatted date e.g. 03-09-2023 (if valid) or empty string (if not valid)
 */
export const toDateLocal = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(date, 'MM-dd-yyyy', TIMEZONE_CONFIG[timezone]);
};
/**
 * Format the given date to a string in the format of 'MM/dd/yyyy'.
 *
 * @param {string | null | Date} date - The date to be formatted.
 * @returns {string} The formatted date e.g. 03/09/2023 (if valid) or empty string (if not valid)
 */

export const toDateSlash = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(date, 'MM/dd/yyyy', TIMEZONE_CONFIG[timezone]);
};
/**
 * Format the given date to a string of 'MM/dd/yyyy, h:mm a' format.
 *
 * @param {string | null | Date} date - The date to be formatted.
 * @returns {string}  The formatted date e.g.03/09/2023, 6:27 AM (if valid) or empty string (if not valid)
 */
export const toDateSlashTime = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(
    date,
    'MM/dd/yyyy, h:mm a',
    TIMEZONE_CONFIG[timezone],
  );
};

/**
 * Format the given date to a time string of 'h:mm aaa' format.
 * @param {string | null | Date} date - The date to format as a time string.
 * @returns {string} The formatted date e.g. 6:27 AM (if valid) or empty string (if not valid)
 */
export const toTime = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(date, 'h:mm aaa', TIMEZONE_CONFIG[timezone]);
};

/**
 * Format the given date string in the "MMM d, yyyy" format.
 * @param {string | null} date - The date string to format.
 * @returns {string} The formatted date string.
 */
export const toDateMMMDDYYYY = (
  date: string | null | Date,
  timezone: TimezoneConfigKey = TIMEZONE_DEFAULT,
) => {
  return getFormattedDate(date, 'MMM d, yyyy', TIMEZONE_CONFIG[timezone]);
};

/**
 * Takes a Zod string schema and returns a refined schema that validates if the
 * provided input is a valid date. If input is null or undefined, it always returns true.
 * If input is a string, it tries to parse it using the provided date format and returns true if the resulting date is
 * valid.
 * If the input is not valid, it throws an error with the message "Incorrect date format".
 * @param {z.ZodString} zod - The Zod string schema to refine.
 * @returns {z.ZodEffects<z.ZodString, string, string>} - A refined Zod string schema that validates if the input is
 * a valid date string
 */
export const formDateStringZodSchema = (zod: z.ZodString) => {
  return zod.refine((val: string | null) => {
    if (!val || val.trim() === '') {
      return true;
    }
    return isValidDate(parseISO(val));
  }, 'Incorrect date format');
};

/**
 * Parse datetime from a string - this can be used for parsing dates from Excel or Google Sheets etc..
 * First checks for an ISO compliant string and uses that if provided
 * Otherwise, the format will be attempted to be detected and parsed
 *
 * Example inputs that are accepted (these are generated from programs like Excel and Google Sheets)
 * 12/12/2020 15:15:00
 * 1/3/2023 13:44:19
 * 1/3/23 1:44 PM
 */
export const parseDatetime = (value: string | null | Date): Date | null => {
  if (!value) {
    return null;
  }
  if (value instanceof Date) {
    return value;
  }
  if (isString(value)) {
    value = value.trim();
    if (ISO_COMPATIBLE_DATE.test(value)) {
      return parseISO(value);
    }
    /**
     * Date will be in a format like this:
     * 1/3/2023 13:44:19 OR 1/3/23 1:44 PM
     */
    value = value.replace('T', ' '); // just in case a T is used for the timezone instead of a space
    const dateParts = value.split(' ');
    // eslint-disable-next-line prefer-destructuring
    const date = dateParts[0];
    const time = dateParts.slice(1).join(' ');
    // TODO: support other locales where date is formatted differently
    let outputDate = ISO_COMPATIBLE_DATE.test(date)
      ? parseISO(date)
      : buildDateFromString(date, DateFormatEnum.MM_DD_YYYY);
    if (outputDate) {
      if (TWENTY_FOUR_HR_TIME.test(time)) {
        outputDate = parseDate(time, 'H:m:ss', outputDate);
      } else if (TWELVE_HR_TIME.test(time)) {
        outputDate = parseDate(time, 'h:m a', outputDate);
      }
      if (isValid(outputDate)) {
        return outputDate;
      } else {
        logger.warn('Unable to parse date', value);
      }
    }
  }
  return null;
};

/**
 * Given a string that is formatted in a non-ISO date format, return a Date object
 *
 * Example input:
 * 12/12/2020
 * 1/3/2023
 * 1/3/23
 *
 * @param value
 * @param dateFormat locale of the date format
 * @returns
 */
export const buildDateFromString = (
  value: string,
  dateFormat: DateFormatEnum,
): Date | null => {
  const refDate = startOfDay(new Date());
  const tempValue = value.replace(NOT_NUMERIC, '-'); // FIXME: some date formats are 'd. m. yyyy' like 'sk-SK'
  let [first, middle, end] = tempValue.split('-');
  if (!first || !middle || !end) {
    return null;
  }
  switch (dateFormat) {
    case DateFormatEnum.MM_DD_YYYY: {
      first = first.padStart(2, '0');
      middle = middle.padStart(2, '0');
      end = end.padStart(4, '20');
      return parseDate(`${first}-${middle}-${end}`, 'MM-dd-yyyy', refDate);
    }
    case DateFormatEnum.DD_MM_YYYY: {
      first = first.padStart(2, '0');
      middle = middle.padStart(2, '0');
      end = end.padStart(4, '20');
      return parseDate(`${first}-${middle}-${end}`, 'dd-MM-yyyy', refDate);
    }
    case DateFormatEnum.YYYY_MM_DD: {
      first = first.padStart(4, '20');
      middle = middle.padStart(2, '0');
      end = end.padStart(2, '0');
      return parseDate(`${first}-${middle}-${end}`, 'yyyy-MM-dd', refDate);
    }
    default:
      throw new Error('Invalid date format');
  }
};
/**
 * Formats a date string as a human-readable string representing the time elapsed since the input date.
 * @param {string | null | Date} date - The date string to format.
 * @returns {string} The human-readable string representing the time elapsed since the input date.
 */
export const fromNow = (date: string | null | Date) => {
  if (date === null) {
    return '';
  }
  const parsedDate = date instanceof Date ? date : parseISO(date);
  return isValidDate(parsedDate)
    ? formatDistanceToNow(parsedDate, { addSuffix: true })
    : '';
};
/**
 * Checks if a given value is a valid date string or Date object.
 * @summary
 * wrapper function for `date-fns`'s `isValid`
 * @param {Date | string | null | undefined} date The date or date string to validate.
 * @returns {boolean} Whether the input is a valid date or not.
 */

export const isValidDate = (
  date: Date | string | null | undefined,
): date is Date => {
  return date instanceof Date && isValid(date);
};

/**
 * Format date using the tenant's timezone as the default.
 *
 * For date fields, this will default to user browser timezone to ensure no date skew
 *
 * If a date is provided (not string) and the value should be a date (not datetime) then caller needs to set to userTimezone to avoid date skew
 */
export const getFormattedDate = (
  date: string | null | Date,
  format: string,
  timezone = TIMEZONE_CONFIG.tenantTimezone,
): string => {
  const isDateWithoutTimeZone = isString(date) && date.length === 10;
  let dateToFormat: Date | null = null;

  if (isValidDate(date)) {
    dateToFormat = date; // typescript knows 100% that date is type Date
  } else if (isString(date) && date.trim() !== '') {
    dateToFormat = parseISO(date);
  }

  if (!dateToFormat || !isValid(dateToFormat)) {
    return '';
  }
  // If there is no timezone, then we don't want to modify the date
  if (isDateWithoutTimeZone) {
    return formatDate(dateToFormat, format);
  }

  try {
    return formatInTimeZone(dateToFormat, timezone, format);
  } catch (error) {
    Sentry.captureException(
      {
        message: 'Error formatting date',
        extra: {
          date,
          dateToFormat,
          format,
          timezone,
        },
      },
      {
        tags: {
          type: 'DATE_FORMATTING',
        },
      },
    );
    logger.error('Error formatting date:', error);
    return dateToFormat.toString();
  }
};

/**
 * Returns the date in the UTC timezone in the given format.
 */
export const getFormattedDateUtc = (
  date: string | null | Date,
  format: string,
): string => {
  return getFormattedDate(date, format, 'UTC');
};

/**
 * Ensures that the input value is a valid Date object. If the input value is null, undefined, or not a valid Date object or string,
 * it returns the provided fallback value.
 *
 * @param {string | Date | null | undefined} date - The value to ensure is a valid Date object or string.
 * @param {Date} fallback - The fallback value to use if the input value is not a valid Date object or string.
 * @returns {Date} A valid Date object.
 */
export const ensureDate = (
  date: Maybe<string | Date>,
  fallback = new Date(),
): Date => {
  if (isValidDate(date)) {
    return date;
  } else if (isString(date) && date.trim() !== '') {
    const dateToFormat = parseISO(date);
    return isValid(dateToFormat) ? dateToFormat : fallback;
  }
  return fallback;
};

/**
 * Takes an ISO date string (e.x. 05-03-2023) and parses it using UTC timezone
 */
export const parseDateAsUtc = (date: string): Date => {
  if (!date || date.length !== 10) {
    // not sure what to return - but this is not a valid date
  }
  return parseISO(`${date}T00:00:00.000Z`);
};

/**
 * This is used in places where the user is entering a date/time in UTC
 * instead of their local timezone. The timezone offset is removed before parsing
 * and is coerced to UTC
 *
 * @param date
 * @returns
 */
export const replaceUserTimezoneWithUtc = (date: string): Date => {
  return toDate(date.substring(0, 19), { timeZone: 'Etc/UTC' });
};

export const adjustTimeZoneOffsetWithUtc = (date: Date): Date => {
  const timeZoneOffset = date.getTimezoneOffset();
  return Math.sign(timeZoneOffset) === -1
    ? subMinutes(date, Math.abs(timeZoneOffset))
    : addMinutes(date, timeZoneOffset);
};

export const sortByDate = (a: string, b: string) => {
  if (isValidDate(parseISO(a)) && isValidDate(parseISO(b))) {
    return new Date(a).valueOf() - new Date(b).valueOf();
  }
  return 0;
};

export const isBetween = (date: Date, startDate: Date, endDate: Date) => {
  return isBefore(date, endDate) && isAfter(date, startDate);
};

export const isBetweenInclusive = (
  date: Date,
  startDate: Date,
  endDate: Date,
) => {
  return (
    (isBefore(date, endDate) || isSameDay(date, endDate)) &&
    (isAfter(date, startDate) || isSameDay(date, startDate))
  );
};
