import { Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { formatCurrency } from '@monetize/utils/core';
import { MdOutlineDesktopWindows } from 'react-icons/md';
import {
  IOfferingRes,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  OnQuoteOfferingChange,
  ProductTypeEnum,
} from '../../types';
import { MBox, MDivider, MIcon, MTable, MText } from '../Monetize';

type PriceDisplayFrequencyConfigurationProps = {
  isReadOnly: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  onOfferingChange: OnQuoteOfferingChange;
  offering: IOfferingRes | null;
  quote?: IQuoteRespSchema | null;
  isOfferingLoading: boolean;
};

export const PriceDisplayFrequencyConfiguration = ({
  isReadOnly,
  quoteOffering,
  onOfferingChange,
  offering,
  quote,
  isOfferingLoading,
}: PriceDisplayFrequencyConfigurationProps) => {
  const products = (quoteOffering?.items || []).slice(0, 2).map((item) => {
    return {
      name: item.productName,
      description: item.description,
      amount: formatCurrency(item.amount, { currency: quote?.currency }),
      quantity: item.productType === ProductTypeEnum.USAGE ? '' : item.quantity,
      unitPrice: formatCurrency(item.unitPrice, { currency: quote?.currency }),
    };
  });

  // If there is only 1 product, we show a dummy product for the example
  if (products.length < 2) {
    products.push({
      name: 'Product',
      description: 'Product Description',
      amount: formatCurrency(50, { currency: quote?.currency }),
      quantity: 1,
      unitPrice: formatCurrency(50, { currency: quote?.currency }),
    });
  }

  return (
    <MBox bg="tWhite.base" p={4} borderRadius={2}>
      <MText fontWeight="bold" fontSize="md" mb="2">
        Display Frequency Settings
      </MText>
      <MDivider my="2" />
      <MText my={1} fontSize="xs" color="tPurple.acGray">
        To make changes to the Display Frequency Settings for each Product,
        hover over the Amount of the product and click the TV icon that appears
        to the right.
      </MText>

      <MText mt={4} mb={1} fontSize="xxs" color="tGray.acGray" fontWeight="600">
        Example:
      </MText>
      <MTable mt={2} variant="preview">
        <Thead>
          <Tr>
            <Th></Th>
            <Th textAlign="center">Quantity</Th>
            <Th textAlign="center" minW="50px">
              Price Per Unit
            </Th>
            <Th textAlign="right" minW="50px">
              Amount
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {products.map((product, index) => (
            <Tr key={index}>
              <Td maxW="120px">
                <MText
                  fontSize="10px"
                  lineHeight="0.8rem"
                  noOfLines={1}
                  fontWeight="semibold"
                >
                  {product.name}
                </MText>
                <MText fontSize="10px" lineHeight="0.8rem" noOfLines={1}>
                  {product.description}
                </MText>
              </Td>
              <Td textAlign="center" fontSize="9px" minW="120px">
                {product.quantity}
              </Td>
              <Td textAlign="center">{product.unitPrice}</Td>
              <Td textAlign="right">
                <MBox
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {product.amount}
                  {index === 0 && (
                    <MIcon
                      as={MdOutlineDesktopWindows}
                      color="tGray.darkPurple"
                      fontSize="sm"
                      ml="1"
                    />
                  )}
                </MBox>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </MTable>
      <MText my={2} fontSize="xs" color="tGray.acGray">
        Clicking the icon will open a menu with a toggle to turn on Custom
        Display Frequency.
      </MText>
    </MBox>
  );
};
